import {LANGUAGE_EN, LANGUAGE_FR} from 'src/constants/locale'
import {useDateOptions} from 'src/hooks/date'

export const stepOneFields = [
  {
    label: 'email',
    placeholder: 'name@domain.com',
    name: 'email' as const,
  },
  {
    label: 'first_name',
    placeholder: 'first_name',
    name: 'first_name' as const,
  },
  {
    label: 'last_name',
    placeholder: 'last_name',
    name: 'last_name' as const,
  },
  {
    label: 'password',
    placeholder: 'at_least_8_characters',
    name: 'password' as const,
    type: 'password',
  },
  {
    label: 'confirm_password',
    placeholder: 'repeat_your_password',
    name: 'confirm_password' as const,
    type: 'password',
  },
]

export const getLanguagesOptions = () => {
  return [
    {
      label: LANGUAGE_EN,
      value: LANGUAGE_EN,
    },
    {
      label: 'Français',
      value: LANGUAGE_FR,
    },
  ]
}

export function useStepTwoFields() {
  const {pastYearOptions, monthOptions, dayOptions} = useDateOptions()

  return [
    {
      placeholder: 'month',
      name: 'month' as const,
      options: monthOptions,
    },
    {
      placeholder: 'day',
      name: 'day' as const,
      options: dayOptions,
    },
    {
      placeholder: 'year',
      name: 'year' as const,
      options: pastYearOptions,
    },
    {
      placeholder: 'language',
      name: 'language' as const,
      options: getLanguagesOptions(),
    },
  ]
}
