import {Order} from 'src/types/search'
import {http} from 'src/services/http'
import {CancelToken} from 'axios'
import {UserTypeEnum} from 'src/types/form'

export async function adminGetUsersApi(
  hostOnly: number,
  guestOnly: number,
  search: string | null,
  order: Order | null,
  orderBy: string | null,
  page = 1,
  limit = 20,
  type?: string | null,
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'get',
    url: '/admin/users',
    params: {
      page,
      limit,
      host_only: hostOnly,
      guest_only: guestOnly,
      order,
      orderBy,
      search,
      type,
    },
    cancelToken: cancelToken,
  })

  return data
}

export async function adminActivateUserApi(userId: number) {
  const {data} = await http.get(`/admin/users/${userId}/activate`)

  return data
}

export async function adminDisableUserApi(userId: number) {
  const {data} = await http.get(`/admin/users/${userId}/disable`)

  return data
}

export async function adminResendORTOnboardingEmailUserApi(userId: number) {
  const data = await http.post(`/admin/users/${userId}/resend_ort_onboarding`)
  return data
}

export async function adminUpdateUserTypeUserApi(
  userId: number,
  newType: UserTypeEnum,
) {
  const data = await http.put(`/admin/users/${userId}/type`, {
    type: newType,
  })
  return data
}

export async function updateLanguageApi(language: string) {
  const {data} = await http.post(`/user/change_language`, {language})

  return data
}

export async function publicResendEmailVerificationApi(email: string) {
  const {data} = await http.post(`/public/resend_verification`, {
    email,
  })

  return data
}

export async function publicVerifyEmailApi(email: string, token: string) {
  const data = await http.post(`/public/verify_email`, {
    email,
    token,
  })

  return data
}

export async function adminGetUserInfoApi(userId: number) {
  const {data} = await http.get(`/admin/users/${userId}/info`)

  return data
}

export async function getUserApi(userId: number) {
  const {data} = await http.get(`/users/${userId}`)

  return data
}

export async function adminSaveNoteApi(userId: number, note: string) {
  const {data} = await http.post(`/admin/users/${userId}/note`, {note})

  return data
}

export async function publicGetUserVerifiedStatusApi(userId: number) {
  const {data} = await http.get(`/public/users/${userId}/is_verified`)

  return data
}

export async function getCollaboratorPermissionAndSettingsApi() {
  const {data} = await http.get(`/user/collaborator_permission_and_settings`)

  return data
}

export async function setEmailApi(email: string) {
  const {data} = await http.put(`/user/set_email`, {email})

  return data
}

export async function adminRemoveUserApi(userId: number) {
  const {data} = await http.delete(`/admin/users/${userId}`)

  return data
}
