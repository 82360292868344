export const globalZIndex = {
  underBase: -1,
  base: 0,
  aboveBase: 1,
  moreAboveBase: 2,

  underHeader: 9,
  header: 10,
  aboveHeader: 11,

  underModal: 99,
  modal: 100,
  aboveModal: 101,

  underPriority: 999,
  priority: 1000,
  abovePriority: 1001,
}
