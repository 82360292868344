import {PropertyProps} from 'src/types/property'
import {BookingProps} from 'src/types/booking'

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
export const MICROSOFT_CLARITY_ID = process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID

export const pageview = (url: any) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'pageview',
    page: url,
  })
}

export const initEcommerce = () => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    ecommerce: null,
  })
}

export const viewSearchProperties = (properties: PropertyProps[]) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: properties.map((p) => {
        return {
          item_id: p.uid,
          item_name: p.name_fr,
          item_category: 'Property',
          quantity: 1,
          ...(p.basic_pricing
            ? {
                price: p.basic_pricing.base_rate,
              }
            : {}),
        }
      }),
    },
  })
}

export const viewUniquePropertyPageUrl = (property: PropertyProps) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_id: property.uid,
          item_name: property.name_fr,
          item_category: 'Property',
          quantity: 1,
          ...(property.basic_pricing
            ? {
                price: property.basic_pricing.base_rate,
              }
            : {}),
        },
      ],
    },
  })
}

export const clickSearchProperty = (property: PropertyProps) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_id: property.uid,
          item_name: property.name_fr,
          item_category: 'Property',
          quantity: 1,
          ...(property.basic_pricing
            ? {
                price: property.basic_pricing.base_rate,
              }
            : {}),
        },
      ],
    },
  })
}

export const onBookingCheckoutStarted = (
  isLoggedIn: boolean,
  property: PropertyProps,
  total12: number,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  //always trigger
  dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_id: property.uid,
          item_name: property.name_fr,
          item_category: 'Property',
          quantity: 1,
          price: total12,
        },
      ],
    },
  })

  //if login, we start checkout process
  if (isLoggedIn) {
    dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        items: [
          {
            item_id: property.uid,
            item_name: property.name_fr,
            item_category: 'Property',
            quantity: 1,
            price: total12,
          },
        ],
      },
    })
  }
}

export const onBookingCheckoutCompleted = (
  property: PropertyProps,
  booking: BookingProps,
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currency: 'CAD',
      value: booking.prices.total12,
      transaction_id: booking.uid,
      items: [
        {
          item_id: property.uid,
          item_name: property.name_fr,
          item_category: 'Property',
          quantity: 1,
          price: booking.prices.total12,
        },
      ],
    },
  })
}

export const userSignUp = (
  //method: 'password' | 'google' | 'facebook',
  user: {
    id: number
    email: string
    created_at?: string
  },
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'sign_up',
    //method: method,
    user: {
      created_at: user.created_at,
      id: user.id,
      email: user.email,
    },
  })
}

export const userLogin = (
  //method: 'password' | 'google' | 'facebook',
  user: {
    id: number
    email: string
    created_at?: string
  },
) => {
  if (!window.dataLayer) return

  const dataLayer = window.dataLayer as any

  dataLayer.push({
    event: 'login',
    // method: method,
    user: {
      created_at: user.created_at,
      id: user.id,
      email: user.email,
    },
  })
}
