import {FieldError, Resolver} from 'react-hook-form'
import {
  validateEmail,
  validateName,
  validateDay,
  validateYear,
  validateMonth,
  validatePassword,
  validateLanguage,
  validateConfirmPassword,
  validateLoginPassword,
  validateAcceptTerms,
} from 'src/services/validation/validateModules'
import {UserFormValues} from 'src/types/form'

// @ts-ignore
const validate: Omit<
  Record<keyof UserFormValues, (value: string) => string | undefined>,
  'confirm_password'
> = {
  day: validateDay,
  year: validateYear,
  email: validateEmail,
  first_name: validateName('First name'),
  last_name: validateName('Last name'),
  month: validateMonth,
  password: validatePassword,
  language: validateLanguage,
  accept_terms: validateAcceptTerms,
  //accept_newsletters: () => undefined, //not required
}

const validateLogin = {
  email: validateEmail,
  password: validateLoginPassword,
}

export const userResolver: Resolver<UserFormValues> = async (
  values: UserFormValues,
) => {
  const errors: Record<string, FieldError> = {}
  const {confirm_password, password} = values
  for (let field in values) {
    const key = field as keyof UserFormValues
    const message =
      key === 'confirm_password'
        ? validateConfirmPassword(confirm_password, password)
        : validate[key](values[key])
    if (message) {
      errors[key] = {
        type: '',
        message,
      }
    }
  }

  return {
    errors,
    values: values.email
      ? {...values, email: values.email.toLowerCase()}
      : values,
  }
}

export const userLoginResolver: Resolver<UserFormValues> = async (
  values: UserFormValues,
) => {
  const errors: Record<string, FieldError> = {}
  for (const field in values) {
    const key = field as keyof UserFormValues

    // @ts-ignore
    const message = validateLogin[key](values[key])

    if (message) {
      errors[key] = {
        type: '',
        message,
      }
    }
  }

  return {
    errors,
    values: values.email
      ? {...values, email: values.email.toLowerCase()}
      : values,
  }
}
