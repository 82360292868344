import {SvgIconProps} from '@material-ui/core/SvgIcon'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

const ArrowDownIcon: React.FC<SvgIconProps> = ({style, ...props}) => {
  return (
    <KeyboardArrowDown
      fontSize="inherit"
      style={{fontSize: 14, ...style}}
      {...props}
    />
  )
}

export default ArrowDownIcon
