export default function Visible(props: {
  when: boolean
  children: React.ReactNode
  css?: boolean
}) {
  const {when, children, css = false} = props

  if (!when && !css) {
    return null
  }

  if (css) {
    return <div className={when ? '' : 'hidden'}>{children}</div>
  }

  return <>{children}</>
}
