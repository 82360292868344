import {useCallback, useEffect} from 'react'
import {useTranslation} from 'next-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import Error from 'src/components/elements/helpers/Error'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppDialog from 'src/components/elements/Dialog'
import AppDivider from 'src/components/elements/AppDivider'
import AppTextField from 'src/components/forms/textField/AppTextField'
import AppLoadingButton from 'src/components/elements/buttons/AppLoadingButton'
import SocialLogin from 'src/components/modules/dialog/SocialLogin'
import {loginAction} from 'src/store/actions/userAction'
import {loginStatusSelector} from 'src/store/selectors/userSelector'
import {ModalType} from 'src/types/modal'
import {UserFormValues} from 'src/types/form'
import {usePrevious} from 'src/hooks/other'
import {userLoginResolver} from 'src/services/validation/user'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'

import {makeStyles} from '@material-ui/core/styles'
//todo convert to styles.module.css or tailwind utility classes
export const useStyles = makeStyles(
  ({breakpoints, palette, spacing}) => ({
    arrowBack: {
      cursor: 'pointer',
      fontSize: 17.15,
    },
  }),
  {index: 1},
)

const initialValues: Partial<UserFormValues> = {
  email: '',
  password: '',
}

const fields = [
  {
    label: 'email',
    placeholder: 'name@domain.com',
    name: 'email' as const,
  },
  {
    label: 'password',
    placeholder: 'at_least_8_characters',
    name: 'password' as const,
    type: 'password',
  },
]

export default function SigninDialog(props: {
  open: boolean
  onClose: () => void
  setModal: React.Dispatch<React.SetStateAction<ModalType>>
}) {
  const {open, onClose, setModal} = props

  const {t} = useTranslation('auth')
  const dispatch = useDispatch()
  const loginStatus = useSelector(loginStatusSelector)
  const error = loginStatus?.error
  const loading = loginStatus?.loading
  const success = loginStatus?.success
  const previousLoading = usePrevious(loading)
  const classes = useStyles()

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<UserFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      ...initialValues,
    },
    resolver: userLoginResolver,
  })

  const handleClose = useCallback(() => {
    reset({...initialValues}, {keepErrors: false, keepValues: false})
    onClose()
  }, [onClose, reset])

  const onLoginUser = (values: UserFormValues) => {
    if (loading) {
      return
    }

    dispatch(loginAction(values))
  }

  useEffect(() => {
    if (previousLoading && !loading && success) {
      reset({...initialValues}, {keepErrors: false, keepValues: false})
      onClose()
    }
  }, [previousLoading, loading, success, reset, onClose])

  return (
    <AppDialog
      maxWidth="sm"
      open={open}
      title={t('sign_in_to_continue')}
      onClose={handleClose}
      childrenLeft={
        <IconButton onClick={() => setModal('question')}>
          <ArrowBack className={classes.arrowBack} />
        </IconButton>
      }
    >
      <Error error={t(error)} />
      <form className="w-full" onSubmit={handleSubmit(onLoginUser)}>
        {fields.map((field) => (
          <AppTextField
            {...field}
            key={field.name}
            label={t(field.label)}
            placeholder={t(field.placeholder)}
            register={register}
            error={Boolean(errors[field.name]?.message)}
            message={
              errors[field.name]
                ? t(errors[field.name]?.message as string)
                : undefined
            }
            style={{marginBottom: 16}}
          />
        ))}
        <div className="flex justify-end mb-6">
          <AppButton onClick={() => setModal('forgot-password')} variant="text">
            {t('forgot_password')}
          </AppButton>
        </div>
        <AppLoadingButton
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          loading={loading}
        >
          {t('sign_in')}
        </AppLoadingButton>
      </form>
      <AppDivider className="my-9">{t('or_continue_with')}</AppDivider>
      <SocialLogin />
    </AppDialog>
  )
}
