import {
  ACCESS_BOOKING,
  ACCESS_CALENDAR,
  ACCESS_INBOX,
  ACCESS_OVERVIEW,
  ACCESS_PROPERTY,
} from 'src/constants/collaboratorAccess'
import {
  PERMISSION_EDIT,
  PERMISSION_OWNER,
  PERMISSION_READ,
} from 'src/constants/collaboratorPermission'
import {usePermissionContext} from 'src/context/PermissionProvider'
import {
  AccessProps,
  CollaboratorPermissionAndSettingsProps,
} from 'src/types/permissionAndAccess'
import {PropertyProps} from 'src/types/property'
import {useIsAdmin, useUserInfo} from './user'
import {USER_ROLE_HOST} from 'src/constants/userRole'
import {SearchBookingPropertyProps} from 'src/types/booking'

export function useHasPermission(
  required:
    | CollaboratorPermissionAndSettingsProps['permission']
    | CollaboratorPermissionAndSettingsProps['permission'][],
) {
  const {can} = usePermissionContext()

  const targets = Array.isArray(required) ? required : [required]
  return targets.some(can)
}

export function useHasPermissionWithProperty(
  required:
    | CollaboratorPermissionAndSettingsProps['permission']
    | CollaboratorPermissionAndSettingsProps['permission'][],
  property?: PropertyProps | SearchBookingPropertyProps | null,
) {
  const userInfo = useUserInfo()
  const isAdmin = useIsAdmin()

  if (!property) {
    return false
  }

  const isOwner = property.user?.id === userInfo.id

  if (isOwner || isAdmin) {
    // if admin or my property then I can do anything
    return true
  }

  if (!property.collaborators) {
    return false
  }

  const collaborator = property.collaborators.find(
    (collaborator) =>
      collaborator.email?.toLowerCase() === userInfo.email.toLowerCase(),
  )
  if (!collaborator) {
    // if not collaborator then I can not do anything
    return false
  }

  const permissions = Array.isArray(required) ? required : [required]
  const hasPermission = permissions.some(
    (permission) => collaborator.permission === permission,
  )

  return hasPermission
}

// creator, admin, collaborator with edit or owner permission can edit property
export function useCanEditProperty(property?: PropertyProps | null) {
  const canEdit = useHasPermissionWithProperty(
    [PERMISSION_EDIT, PERMISSION_OWNER],
    property,
  )

  return canEdit
}

export function useHasAccess(
  access:
    | typeof ACCESS_PROPERTY
    | typeof ACCESS_OVERVIEW
    | typeof ACCESS_INBOX
    | typeof ACCESS_BOOKING
    | typeof ACCESS_CALENDAR,
) {
  const {permission: userPermission} = usePermissionContext()
  const hasPermission = useHasPermission([
    PERMISSION_READ,
    PERMISSION_EDIT,
    PERMISSION_OWNER,
  ])

  if (!userPermission) {
    // if null it means not registered as a collaborator
    return true
  }

  if (!hasPermission) {
    // if permission is set to NONE
    return false
  }

  const {settings} = userPermission
  const {access: userAccess} = settings

  if (!userAccess) {
    return false
  }

  return userAccess[access]
}

export function useCanSwitchMenu() {
  const userInfo = useUserInfo()

  const {permission: userPermission} = usePermissionContext()
  const hasPermission = useHasPermission([
    PERMISSION_READ,
    PERMISSION_EDIT,
    PERMISSION_OWNER,
  ])

  if (userInfo?.role === USER_ROLE_HOST) {
    return true
  }

  if (!userPermission) {
    // if null it means not registered as a collaborator
    return false
  }

  if (!hasPermission) {
    // if permission is set to NONE
    return false
  }

  const {settings} = userPermission
  const {access: userAccess} = settings

  if (!userAccess) {
    return false
  }

  return (
    userAccess[ACCESS_BOOKING] ||
    userAccess[ACCESS_INBOX] ||
    userAccess[ACCESS_OVERVIEW] ||
    userAccess[ACCESS_PROPERTY]
  )
}

export function useHasAccessWithProperty(
  required: AccessProps | AccessProps[],
  property?: PropertyProps | SearchBookingPropertyProps | null,
) {
  const userInfo = useUserInfo()
  const isAdmin = useIsAdmin()
  const hasPermission = useHasPermission([
    PERMISSION_READ,
    PERMISSION_EDIT,
    PERMISSION_OWNER,
  ])

  if (!property) {
    return false
  }

  const isOwner = property.user?.id === userInfo.id

  if (isOwner || isAdmin) {
    // if admin or my property then I can access anything
    return true
  }

  if (!property.collaborators) {
    return false
  }

  if (!hasPermission) {
    // if permission is set to NONE
    return false
  }

  const collaborator = property.collaborators.find(
    (collaborator) =>
      collaborator.email?.toLowerCase() === userInfo.email.toLowerCase(),
  )
  if (!collaborator) {
    // if not collaborator then I can not access anything
    return false
  }

  const accessList = Array.isArray(required) ? required : [required]
  const collaboratorAccessList = collaborator.settings.access
  if (!collaboratorAccessList) {
    // if not setup access then I can not access anything
    return false
  }

  for (const access of accessList) {
    if (collaboratorAccessList[access]) {
      return true
    }
  }

  return false
}
