const APP_KEY = process.env.NEXT_PUBLIC_PUSHER_APP_KEY || ''
const APP_CLUSTER = process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER || ''
const API_URL = process.env.NEXT_PUBLIC_API_URL || ''

export async function createPusher(token: string) {
  const Pusher = (await import('pusher-js')).default

  return new Pusher(APP_KEY, {
    cluster: APP_CLUSTER,
    authEndpoint: `${API_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  })
}
