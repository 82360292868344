import Alert from '@material-ui/lab/Alert'

export default function Error(props: {
  error?: string | boolean | null
  className?: string
}) {
  const {error, className = ''} = props

  if (!error) {
    return null
  }

  return (
    <Alert severity="error" className={`mb-3 ${className}`}>
      {error}
    </Alert>
  )
}
