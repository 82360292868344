import clsx from 'clsx'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import {InputBaseProps} from '@material-ui/core/InputBase'
import InputAdornment from '@material-ui/core/InputAdornment'
import {makeStyles, Theme} from '@material-ui/core/styles'
import ArrowDownIcon from 'src/components/elements/icons/ArrowDown'
import AppTypography from 'src/components/elements/typography/AppTypography'
import EnhancedInput from './EnhancedInput'
import {FormOption} from 'src/types/form'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles<Theme, {hasIcon: boolean}>(
  ({palette, spacing}) => ({
    helperText: {
      alignItems: 'center',
      display: 'flex',
      gap: spacing(0.75),
      marginTop: spacing(0.5),
    },
    icon: {
      right: spacing(21 / 8),
      top: 'calc(50% - 7px)',
    },

    inputBase: (params) => ({
      '& > input': {
        padding: params.hasIcon ? spacing(0, 13 / 8) : spacing(0, 2.5),
        height: spacing(46 / 8),
        ...(params.hasIcon
          ? {border: 'none'}
          : {
              border: `1px solid ${palette.divider}`,
            }),
      },
      '& .MuiInputAdornment-positionStart': {
        paddingLeft: spacing(2),
      },
      borderRadius: spacing(3),
      border: params.hasIcon ? `1px solid ${palette.divider}` : 'none',
      /* Chrome, Safari, Edge, Opera */
      '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      /* Firefox */
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    }),
    // inputBase: {
    //   '& > input': {
    //     padding: spacing(0, 13 / 8),
    //     height: spacing(46 / 8),
    //   },
    //   '& .MuiInputAdornment-positionStart': {
    //     paddingLeft: spacing(2),
    //   },
    //   borderRadius: spacing(3),
    //   // border: `1px solid ${palette.divider}`,
    //   /* Chrome, Safari, Edge, Opera */
    //   '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    //     '-webkit-appearance': 'none',
    //     margin: 0,
    //   },
    //   /* Firefox */
    //   '& input[type=number]': {
    //     '-moz-appearance': 'textfield',
    //   },
    // },
    error: {
      fontSize: 16,
    },
  }),
  {index: 1},
)

interface Props extends Omit<InputBaseProps, 'name'> {
  description?: React.ReactNode
  label?: React.ReactNode
  message?: string
  select?: boolean
  withLabel?: boolean
  options?: FormOption[]
  optional?: boolean
  register?: any
  name?: string
  icon?: any
  optionalLabel?: string
  isControl?: boolean
  labelChildren?: React.ReactNode
}

function Container(props: {
  isControl: boolean
  style?: any
  children?: React.ReactNode
}) {
  if (props.isControl) {
    return (
      <FormControl fullWidth style={props.style}>
        {props.children}
      </FormControl>
    )
  }
  return (
    <div className="w-full" style={props.style}>
      {props.children}
    </div>
  )
}

export default function AppTextField({
  className,
  description,
  error,
  label,
  message,
  name,
  options,
  optional,
  select,
  style,
  withLabel,
  register,
  value,
  onChange,
  icon,
  optionalLabel,
  labelChildren,
  isControl = true,
  ...props
}: Props) {
  const classes = useStyles({hasIcon: !!icon})

  return (
    <Container isControl={isControl} style={style}>
      {select && options ? (
        <>
          <Label
            name={name}
            label={label}
            optional={optional}
            optionalLabel={optionalLabel}
            hidden={!withLabel}
          >
            {labelChildren}
          </Label>
          <Select
            label={label}
            name={name}
            id={name}
            fullWidth
            error={error}
            input={<EnhancedInput />}
            aria-describedby={`${name}-helper-text`}
            IconComponent={ArrowDownIcon}
            classes={{
              icon: classes.icon,
            }}
          >
            <MenuItem value={''}>{label}</MenuItem>
            {options.map(({label, value}) => (
              <MenuItem value={value as string} key={String(value)}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <>
          <Label
            name={name}
            label={label}
            optional={optional}
            optionalLabel={optionalLabel}
          >
            {labelChildren}
          </Label>
          <EnhancedInput
            className={clsx(classes.inputBase, className)}
            name={name}
            fullWidth
            error={error}
            aria-describedby={`${name}-helper-text`}
            onChange={register ? register(name).onChange : onChange}
            onBlur={register && register(name).onBlur}
            inputRef={register && register(name).ref}
            onWheel={(e: any) => e.target.blur()}
            {...props}
            value={value}
            startAdornment={
              icon && <InputAdornment position="start">{icon}</InputAdornment>
            }
          />
        </>
      )}

      {error && message ? (
        <Typography
          className={classes.helperText}
          id={`${name}-helper-text`}
          variant="caption"
          color="error"
        >
          <ErrorOutline
            color="error"
            fontSize="inherit"
            className={classes.error}
          />
          {message}
        </Typography>
      ) : (
        description
      )}
    </Container>
  )
}

export function Label(props: {
  label?: React.ReactNode
  hidden?: boolean
  optional?: boolean
  optionalLabel?: string
  name?: string
  children?: React.ReactNode
  noPadding?: boolean
  style?: any
}) {
  const {
    noPadding,
    label,
    optional,
    hidden,
    optionalLabel,
    name,
    children,
    style,
  } = props

  if (hidden || !label) {
    return null
  }

  return (
    <div
      className={`flex flex-row justify-between items-center gap-1 ${
        noPadding ? 'mb-0' : 'mb-1'
      }`}
    >
      <div className="flex flex-row justify-start items-center gap-1">
        <AppTypography
          variant="action"
          neutralColor={800}
          component="label"
          htmlFor={name}
          style={style}
        >
          {label}
        </AppTypography>
        <LabelRight optional={optional} optionalLabel={optionalLabel} />
      </div>
      {children}
    </div>
  )
}

function LabelRight(props: {optional?: boolean; optionalLabel?: string}) {
  const {optional, optionalLabel} = props

  if (optional && !optionalLabel) {
    return null
  }

  if (optionalLabel) {
    return (
      <AppTypography component="span" neutralColor={400} variant="body">
        {optionalLabel}
      </AppTypography>
    )
  }

  return (
    <AppTypography
      component="span"
      className="text-primary-main"
      variant="body"
    >
      *
    </AppTypography>
  )
}
