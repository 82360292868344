import BackdropComponent from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function LoadingBackdrop(props: {open: boolean}) {
  return (
    <BackdropComponent
      className="z-under-modal text-primary-main"
      open={props.open}
    >
      <CircularProgress color="inherit" />
    </BackdropComponent>
  )
}
