import axios from 'axios'
import {BASE_URL} from 'src/constants/route'

export async function fetchBuildId() {
  const data = await axios.get(`${BASE_URL}/api/build_id`)
  try {
    return data.data.buildId
  } catch (e) {
    return null
  }
}
