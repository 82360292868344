import {AnyAction} from 'redux'
import {SET_BADGE} from 'src/store/actionTypes'

const initialState: any = {
  host_badge: 0,
  guest_badge: 0,
}

const messageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_BADGE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default messageReducer
