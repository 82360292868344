import {createSelector} from 'reselect'
import {RootState} from 'src/store'

const globalDataSelector = (state: RootState) => state.global

export const documentWidthSelector = createSelector(
  globalDataSelector,
  (payload) => payload.documentWidth,
)

export const documentHeightSelector = createSelector(
  globalDataSelector,
  (payload) => payload.documentHeight,
)
