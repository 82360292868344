import {createContext, useContext, useState} from 'react'
import {GuestSelectProps} from 'src/types/guestSelect'
import {SelectDateProps} from 'src/types/utils'

interface PreviousBookingInformationContextProps {
  previousGuests: GuestSelectProps
  previousDates: SelectDateProps
  setPreviousDates: (dateRange: SelectDateProps) => void
  setPreviousGuests: (guests: GuestSelectProps) => void
}

const PreviousBookingInformationContext = createContext<
  PreviousBookingInformationContextProps | undefined
>(undefined)

PreviousBookingInformationContext.displayName =
  'PreviousBookingInformationContext'

export const usePreviousBookingInformationContext = () => {
  const context = useContext(PreviousBookingInformationContext)
  if (context === undefined)
    throw new Error(
      `${PreviousBookingInformationContext.displayName} must be used within provider`,
    )

  return context
}

export default function PreviousBookingInformationProvider(props: {
  children: React.ReactChild
}) {
  const [previousDates, setPreviousDates] = useState<SelectDateProps>([
    null,
    null,
  ])
  const [previousGuests, setPreviousGuests] = useState<GuestSelectProps>({
    adults: 2, //2 by default
    infants: 0,
    children: 0,
    animals: 0,
  })

  return (
    <PreviousBookingInformationContext.Provider
      value={{
        setPreviousDates,
        setPreviousGuests,
        previousGuests,
        previousDates,
      }}
    >
      {props.children}
    </PreviousBookingInformationContext.Provider>
  )
}
