import ErrorOutline from '@material-ui/icons/ErrorOutline'
import AppTypography from 'src/components/elements/typography/AppTypography'

export default function InputError(props: {
  children?: React.ReactElement | string | null
}) {
  const {children} = props
  if (!children) {
    return null
  }

  return (
    <AppTypography
      className="flex flex-row justify-center items-center gap-2 mt-2 text-error-main"
      variant="caption"
    >
      <ErrorOutline color="error" fontSize="inherit" className="text-base" />
      {children}
    </AppTypography>
  )
}
