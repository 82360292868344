import Typography from '@material-ui/core/Typography'
function DividerLine(props: {className?: string}) {
  return (
    <div className={`flex w-full h-px bg-divider ${props.className || ''}`} />
  )
}

export default function AppDivider(props: {
  className?: string
  children?: React.ReactNode
}) {
  if (props.children)
    return (
      <div
        className={`flex flex-col justify-start items-center gap-1 ${
          props.className || ''
        }`}
      >
        <DividerLine />
        <Typography variant="caption" className="text-text-secondary">
          {props.children}
        </Typography>
        <DividerLine />
      </div>
    )

  return <DividerLine className={props.className} />
}
