import {createContext, useContext, useState} from 'react'
import {UseFormReturn, useForm} from 'react-hook-form'
import {listingResolver} from 'src/services/validation/listing'
import {PropertyProps} from 'src/types/property'

interface AddListingContextValue {
  form: UseFormReturn<PropertyProps>
  loading: boolean
  setLoading: (loading: boolean) => void
  currentStep: number
  setCurrentStep: (currentStep: number) => void
}

export const AddListingContext = createContext<
  AddListingContextValue | undefined
>(undefined)

AddListingContext.displayName = 'AddListingContext'

export const useAddListingContext = () => {
  const context = useContext(AddListingContext)
  if (context === undefined)
    throw new Error(
      `${AddListingContext.displayName} must be used within provider`,
    )

  return context
}

export default function AddListingProvider(props: {
  children: React.ReactChild
}) {
  const [loading, setLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const form = useForm<PropertyProps>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      id: 0, //todo change to null ?
      accept_conditions: false,
      name_fr: '',
      sub_name_fr: '',
      name_en: '',
      sub_name_en: '',
      current_step: 1,
      amenities: [],
      country: '',
      city: '',
      customer_expectations: [],
      full_address: '',
      number_of_rooms: 0,
      number_of_rooms_bath: 0,
      number_of_open_concept_rooms: 0,
      postal_code: '',
      securities: [],
      state: '',
      mrc: '',
      administrative_area: '',
      rooms: [],
      number_of_queen: 0,
      number_of_bunk: 0,
      number_of_sleeping: 0,
      number_of_sofa_bed: 0,
      number_of_couch: 0,
      number_of_king_size: 0,
      rental_parameter: {
        booking_preferences: 'reservation_request',
        min_nights_to_rent: 1,
        max_nights_to_rent: 0,
        arriving_time: '17:00',
        departure_time: '11:00',
        advanced_booking_options: {
          'allow_same_day_departure/arrival': true,
          months_in_advance_receive_reservations: 12,
          arrival_departure_days: [
            'Mon',
            'Tue',
            'Wed',
            'Thu',
            'Fri',
            'Sat',
            'Sun',
          ],
        },
      },
      type: '',
    },
    resolver: listingResolver,
  })

  return (
    <AddListingContext.Provider
      value={{form, loading, setLoading, currentStep, setCurrentStep}}
    >
      {props.children}
    </AddListingContext.Provider>
  )
}
