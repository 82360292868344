import React, {useEffect} from 'react'

/*
//for real debug purpose
if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    trackExtraHooks: [[require('react-redux'), 'useSelector']],
  })
}
*/
//import at top so it's included in first in the final css file
//import all css in _app to make sure we don't miss css loading chunk failed issue...
//import before tailwind so we can overwrite with tailwind
import 'react-phone-input-2/lib/style.css' //import global
import 'leaflet/dist/leaflet.css' //import global for maps
import 'swiper/css' //slider css
import 'swiper/css/effect-fade' //slider css
import 'swiper/css/navigation' //slider css
import 'swiper/css/pagination' //slider css
import 'swiper/css/keyboard' //slider css

//import tailwind and scss
import 'src/assets/scss/main.scss'

import Head from 'next/head'
import {ThemeProvider} from '@material-ui/core/styles'
import {AppProps} from 'next/app'
import {Provider as SessionProvider} from 'next-auth/client'
import {Provider} from 'react-redux'
import {appWithTranslation} from 'next-i18next'
import NProgress from 'nprogress'
import {theme} from 'src/components/materialTheme/theme'
import {Router, useRouter} from 'next/router'
import AddListingProvider from 'src/context/AddListingProvider'
import PusherProvider from 'src/context/PusherProvider'
import AppProvider from 'src/context/AppProvider'
import store from 'src/store'
import {BASE_URL, HOME_PAGE} from 'src/constants/route'
import {StylesProvider, createGenerateClassName} from '@material-ui/core/styles'
import RedirectProvider from 'src/context/RedirectProvider'
import {useCurrentLocale} from 'src/hooks/locale'
import PermissionProvider from 'src/context/PermissionProvider'
import {DEFAULT_LOCALE} from 'src/constants/locale'
import MenuProvider from 'src/context/MenuProvider'
import OnLoadContainer from 'src/components/hoc/OnLoadContainer'
import LoginProvider from 'src/context/LoginProvider'
import UserProvider from 'src/context/UserProvider'
import PreviousBookingInformationProvider from 'src/context/PreviousBookingInformationProvider'
import {SpeedInsights} from '@vercel/speed-insights/react'
import {create} from 'jss'
import {jssPreset} from '@material-ui/core'
import {LOGO_URLS} from 'src/constants/global'

const isProduction = process.env.NODE_ENV === 'production'

const jss = create({
  ...jssPreset(),
  insertionPoint:
    typeof window !== 'undefined'
      ? (document.querySelector('#jss-mui4-insertion-point') as HTMLElement) //element is defined in _document.tsx
      : undefined,
})
const generateClassName = createGenerateClassName({
  productionPrefix: 'jss',
})

Router.events.on('routeChangeStart', (url, {shallow}) => {
  if (shallow) return
  NProgress.configure({
    showSpinner: false,
  })
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function App({Component, pageProps}: AppProps) {
  const canonicalUrl = useCanonicalUrl()

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <link rel="canonical" href={canonicalUrl} />
        <meta name="referrer" content="no-referrer" />
        <meta name="theme-color" content="#000000" />
        {process.env.GLOBAL_NOINDEX === 'true' && (
          <meta name="robots" content="noindex" />
        )}

        <meta charSet="utf-8" />
        <meta name="type" content="website" />
        <link href="/favicon.png" rel="shortcut icon" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta httpEquiv="Expires" content="3" />
        <link rel="preconnect" href="https://api.monsieurchalets.com" />
        <link rel="preconnect" href="https://d1u8deot2vr3j.cloudfront.net" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Outfit:wght@400;500;700&display=swap"
          rel="stylesheet"
        />

        {Object.values(LOGO_URLS).map((url) => (
          <link key={url} rel="preload" href={url} as="image" />
        ))}
      </Head>
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <>
            <SessionProvider session={pageProps.session}>
              <Provider store={store}>
                <LoginProvider>
                  <RedirectProvider>
                    <AppProvider>
                      <UserProvider>
                        <PusherProvider>
                          <AddListingProvider>
                            <PermissionProvider>
                              <MenuProvider>
                                <OnLoadContainer>
                                  <PreviousBookingInformationProvider>
                                    <Component {...pageProps} />
                                  </PreviousBookingInformationProvider>
                                </OnLoadContainer>
                              </MenuProvider>
                            </PermissionProvider>
                          </AddListingProvider>
                        </PusherProvider>
                      </UserProvider>
                    </AppProvider>
                  </RedirectProvider>
                </LoginProvider>
              </Provider>
            </SessionProvider>
          </>
        </ThemeProvider>
      </StylesProvider>
      {isProduction && <SpeedInsights />}
    </>
  )
}

export default appWithTranslation(App)

function useCanonicalUrl() {
  const currentLocale = useCurrentLocale()
  const router = useRouter()

  const pathName = router.asPath

  return (
    BASE_URL +
    (currentLocale !== DEFAULT_LOCALE ? `/${currentLocale}` : '') +
    (pathName === HOME_PAGE ? '' : pathName)
  )
}
