import {SvgIconProps} from '@material-ui/core/SvgIcon'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'

const ArrowLeftIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <KeyboardArrowLeft
      fontSize="inherit"
      style={{fontSize: '19px'}}
      {...props}
    />
  )
}

export default ArrowLeftIcon
