import axios from 'axios'
import https from 'https'
import Cookies from 'js-cookie'
import {APP_SLUG} from 'src/constants/slug'
import {v4, validate} from 'uuid'
import store from 'src/store'
import * as Sentry from '@sentry/nextjs'

export const http = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/api/v1`,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
})

export function setSessionId(sessionId?: string | null) {
  if (!sessionId) {
    sessionId = v4() //session id will be a uuid
  }

  localStorage.setItem('custom_session_id', sessionId)
  Cookies.set('custom_session_id', sessionId)
  http.defaults.headers.common['x-custom-session-id'] = sessionId
}

export function regenerateSessionId() {
  deleteSessionId()
  setSessionId()
}

export function deleteSessionId() {
  delete http.defaults.headers.common['x-custom-session-id']
  http.defaults.headers.common['x-custom-session-id'] = ''
  localStorage.removeItem('custom_session_id')
  Cookies.remove('custom_session_id')
}

export function setJwtToken(token: string) {
  localStorage.setItem('token', token)
  http.defaults.headers.common.Authorization = `Bearer ${token}`
  Cookies.set('jwt', token)
}

export function deleteJwtToken() {
  delete http.defaults.headers.common.Authorization
  localStorage.removeItem('token')
  Cookies.remove('jwt')
  http.defaults.headers.common.Authorization = ''
}

if (process.env.NODE_ENV !== 'production') {
  //add random number
  http.defaults.headers.common['XDEBUG_SESSION'] = Math.floor(
    Math.random() * 100,
  ).toString()

  http.defaults.headers.common['XDEBUG_TRIGGER'] = Math.floor(
    Math.random() * 100,
  ).toString()
}

//add site slug
http.defaults.headers.common['x-app-slug'] = APP_SLUG

//on server, add key so we don't get throttle issue when building vercel
if (typeof window === 'undefined') {
  http.defaults.headers.common['x-next-build-key'] =
    process.env.API_THROTTLE_KEY
}

//if we are on the server, add header
if (typeof window !== 'undefined') {
  const token = localStorage.getItem('token')
  let sessionId = localStorage.getItem('custom_session_id')

  //validate v4 sessionId so if we have random string, its recreated
  if (sessionId && !validate(sessionId)) {
    sessionId = null //will be recreated
  }

  if (token) {
    //set token, and create session id if it's not provided
    setJwtToken(token)
  }

  //if dont exist, it will be create
  setSessionId(sessionId)
}

http.interceptors.response.use(
  function (response) {
    const authorization = response.headers.authorization
    if (authorization) {
      setJwtToken(authorization)
    }
    return response
  },
  function (error) {
    if (error.response) {
      const authorization = error.response.headers.authorization
      if (authorization) {
        setJwtToken(authorization)
      }

      if (error.response.status === 403) {
        //only logout if the user is not admin
        const isAdmin = store.getState().user.login?.user?.is_admin
        if (!isAdmin) {
          //403 means the server return a reponse deny, so the request is not allowed
          //a 403 may also occurs because cloudflare block this server/browser...
          // so if it's inside the vercel robot, we need to whitelist the IP address on cloudflare waf settings

          deleteJwtToken()
          localStorage.removeItem('persist:chalet-root')

          if (window.location.href !== `${window.location.origin}/`) {
            window.location.href = window.location.origin
          }
        }

        Sentry.captureException(error)
      }
    }
    return Promise.reject(error)
  },
)

export const handleHttpException =
  (setter: (message: string | null) => void, t?: any, ns?: string) =>
  (ex: any) => {
    const error = ex.response?.data
    if (error && t && ns) {
      setter(t(error.message, {ns}))
      return
    }

    if (!error && t && ns) {
      setter(t(ex.message, {ns}))
      return
    }

    if (error) {
      setter(t ? t(error.message) : error.message)
      return
    }

    setter(t ? t(ex.message) : ex.message)
  }
