import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {createContext, useContext, useEffect, useState} from 'react'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import AppDialog from 'src/components/elements/Dialog'
import AppTextField from 'src/components/forms/textField/AppTextField'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {HOME_PAGE} from 'src/constants/route'
import {useUserInfo} from 'src/hooks/user'
import {validateEmail} from 'src/services/validation/validateModules'
import {FieldError, Resolver, useForm} from 'react-hook-form'
import {ValidationError, fieldError} from 'src/hooks/form'
import {setEmailApi} from 'src/services/api/user'
import {useDispatch} from 'react-redux'
import {getMeAction} from 'src/store/actions/userAction'

interface UserContextProps {}

export const UserContext = createContext<UserContextProps | undefined>(
  undefined,
)

UserContext.displayName = 'UserContext'

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (context === undefined)
    throw new Error(`${UserContext.displayName} must be used within provider`)

  return context
}

//todo convert all this to other place and not a provider....
export default function UserProvider(props: {children: React.ReactChild}) {
  const [open, setOpen] = useState(false)

  const userInfo = useUserInfo()
  const router = useRouter()

  useEffect(() => {
    if (!userInfo) {
      return
    }

    const isFakeEmail = userInfo.is_fake_email
    if (!isFakeEmail) {
      setOpen(false)
      return
    }

    if (router.asPath !== HOME_PAGE) {
      router.push(HOME_PAGE)
    }

    setOpen(true)
  }, [router, userInfo])

  //todo change this to not use user context
  return (
    <UserContext.Provider value={{}}>
      {open && (
        <SetEmailDialog open={open} handleClose={() => setOpen(false)} />
      )}
      {props.children}
    </UserContext.Provider>
  )
}

type FormValues = {
  email: string
}

const validate: Record<
  keyof FormValues,
  (value: string) => string | undefined
> = {
  email: validateEmail,
}

const formResolver: Resolver<FormValues> = async (values: FormValues) => {
  const errors: Record<string, FieldError> = {}
  for (let field in values) {
    const key = field as keyof FormValues
    const message = validate[key](values[key])

    if (message) {
      errors[key] = {
        type: '',
        message,
      }
    }
  }

  return {
    errors,
    values,
  }
}

function SetEmailDialog(props: {open: boolean; handleClose: () => void}) {
  const {open, handleClose} = props

  const {t} = useTranslation('common')
  const dispatch = useDispatch()

  const [serverError, setServerError] = useState<ValidationError<any>>(null)
  const [submitting, setSubmitting] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: formResolver,
  })

  const emailError = fieldError('email', {
    form: errors,
    response: serverError,
  })

  const onSubmit = (formData: FormValues) => {
    setServerError(null)
    setSubmitting(true)
    setEmailApi(formData.email)
      .then(() => {
        dispatch(getMeAction())
        handleClose()
      })
      .catch((ex) => setServerError(ex.response.data))
      .finally(() => setSubmitting(false))
  }

  return (
    <AppDialog
      maxWidth="sm"
      open={open}
      title={t('Add an email related to your social account')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2 ">
          <AppTypography variant="body">
            {t(
              'Your social account doesn’t have an email attached to it. Please add the email where you will be able to receive notifications related to your future bookings.',
            )}
          </AppTypography>
          <AppTextField
            required
            placeholder="Email"
            type="email"
            label={t('Your email')}
            error={Boolean(emailError)}
            message={emailError ? t(emailError) : ''}
            inputProps={register('email', {
              required: `${t('Email is required')}`,
            })}
          />
          <AppRoundedButton
            variant="contained"
            type="submit"
            color="primary"
            disabled={submitting}
          >
            {t('Add my email')}
          </AppRoundedButton>
        </div>
      </form>
    </AppDialog>
  )
}
