import {CookieConsentSettingsType} from 'src/types/cookies'

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: any) => {
  if (!window.gtag) return
  const gtag = window.gtag as any
  gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events

export const event = ({
  action,
  category,
  label,
  value,
}: {
  action: string
  category: string
  label: string
  value: string
}) => {
  if (!window.gtag) return
  const gtag = window.gtag as any
  gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

export const updateConsent = (
  cookieConsentSettings: CookieConsentSettingsType,
) => {
  if (!window.gtag) return
  const gtag = window.gtag as any

  gtag('consent', 'update', {
    // ad_storage: 'denied',
    ad_user_data: cookieConsentSettings.consentStatsCollection
      ? 'granted'
      : 'denied',
    ad_personalization: cookieConsentSettings.consentMarketings
      ? 'granted'
      : 'denied',
    //analytics_storage: 'denied',
  })
}
