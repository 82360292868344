import Link from 'next/link'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import {TypographyProps} from '@material-ui/core/Typography'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({palette}) => ({
  container: {
    textDecoration: 'none',
  },
  typography: {
    display: 'block',
    cursor: 'pointer',
    color: palette.grey[600],
    fontSize: 14,
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.02em',
  },
  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

interface Props extends TypographyProps<'a'> {
  href: string
  newTab?: boolean
  shouldRefresh?: boolean
  className?: string
  underlineOnHover?: boolean
  onClick?: (e: any) => void
  ariaLabel?: string
}

const AppLink: React.FC<Props> = ({
  href,
  className,
  newTab,
  shouldRefresh,
  underlineOnHover,
  children,
  onClick,
  ariaLabel,
}) => {
  const classes = useStyles()

  const styles = clsx(
    classes.container,
    classes.typography,
    className ? className : undefined,
    underlineOnHover ? classes.underlineOnHover : undefined,
  )
  const hrefProps = {
    'aria-label': ariaLabel ? ariaLabel : undefined,
    ...(newTab ? {target: '_blank', rel: 'noopener noreferrer'} : {}),
  }

  if (shouldRefresh) {
    return (
      <a href={href} {...hrefProps} className={styles} onClick={onClick}>
        {children}
      </a>
    )
  }

  return (
    <Link href={href} passHref onClick={onClick}>
      <a {...hrefProps} className={styles}>
        {children}
      </a>
    </Link>
  )
}

export default AppLink
