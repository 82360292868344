import {useTranslation} from 'next-i18next'
import AppModal from 'src/components/elements/AppModal'
import Form from './Form'

export default function ForgotPassword(props: {
  open: boolean
  onClose: () => void
}) {
  const {t} = useTranslation('auth')

  const {open, onClose} = props

  return (
    <AppModal title={t('forgot_password')} open={open} handleClose={onClose}>
      <Form />
    </AppModal>
  )
}
