import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(({palette, spacing}) => ({
  form: {
    gap: spacing(3),
  },
  success: {
    borderRadius: spacing(6 / 8),
    gap: spacing(2),
    backgroundColor: '#E2F4EE',
    padding: spacing(1.5, 2),
  },
  message: {
    color: palette.primary.main,
  },
}))
