import {dayjs} from 'src/utils/date'

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export function isNumeric(str: any) {
  if (!str) {
    return false
  }

  if (typeof str !== 'string') {
    return false
  }

  return !isNaN(Number(str)) && !isNaN(parseFloat(str))
}

export function isDate(dateStr: any, format: string = 'YYYY-MM-DD') {
  if (!dateStr) {
    return false
  }

  if (typeof dateStr !== 'string') {
    return false
  }

  try {
    return dayjs(dateStr, format).format(format) === dateStr
  } catch (e) {
    return false
  }
}
