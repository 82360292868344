export default function AppIcon(props: {
  name: string
  alt?: string
  style?: React.CSSProperties
  className?: string
  hidden?: boolean
}) {
  const {name, alt, style, className, hidden} = props

  if (hidden) {
    return null
  }

  return (
    <img
      loading="lazy"
      draggable={false}
      alt={alt ?? 'app icon'}
      src={`/images/icons/${name}.svg`}
      style={style}
      className={className}
    />
  )
}
