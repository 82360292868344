import clsx from 'clsx'
import classes from './styles.module.scss'
import {ButtonProps} from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'

export default function AppLoadingButton(
  props: {
    loading: Boolean
    children: React.ReactElement | string
  } & ButtonProps,
) {
  const {loading, children} = props
  const className = clsx(props.className, classes.AppLoadingButton)

  return (
    <AppRoundedButton {...props} className={className}>
      {loading ? <CircularProgress size={20} /> : <>{children}</>}
    </AppRoundedButton>
  )
}
