import axios, {CancelTokenSource} from 'axios'
import {MutableRefObject} from 'react'
import {ValidationError} from 'src/hooks/form'
import {publicCheckIfPasswordIsValidApi} from 'src/services/api/auth'

export function checkIsPasswordValid(
  password: string,
  checkPasswordCancelSource: MutableRefObject<CancelTokenSource | null>,
  setServerError: (error: ValidationError<any>) => void,
) {
  if (!password) {
    setServerError(null)
    return
  }

  if (password.length < 8) {
    setServerError(null)
    return
  }

  if (checkPasswordCancelSource.current) {
    checkPasswordCancelSource.current.cancel()
  }

  checkPasswordCancelSource.current = axios.CancelToken.source()
  publicCheckIfPasswordIsValidApi(
    password,
    checkPasswordCancelSource.current?.token,
  )
    .then(() => setServerError(null))
    .catch((ex) => {
      if (axios.isCancel(ex)) {
        return
      }
      if (!ex || !ex.response || !ex.response.data) {
        setServerError(null)
        return
      }

      setServerError(ex.response.data)
    })
}
