import {useCallback} from 'react'
import {useTranslation} from 'next-i18next'
import {useForm} from 'react-hook-form'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppDialog from 'src/components/elements/Dialog'
import AppDivider from 'src/components/elements/AppDivider'
import SocialLogin from 'src/components/modules/dialog/SocialLogin'
import {ModalType} from 'src/types/modal'
import {UserFormValues} from 'src/types/form'
import {userResolver} from 'src/services/validation/user'
import {useRedirectContext} from 'src/context/RedirectProvider'

const initialValues: Partial<UserFormValues> = {
  email: '',
  password: '',
}

export default function QuestionDialog(props: {
  open: boolean
  onClose: () => void
  setModal: React.Dispatch<React.SetStateAction<ModalType>>
}) {
  const {open, onClose, setModal} = props

  const {clearRedirect} = useRedirectContext()
  const {t} = useTranslation('auth')
  const {reset} = useForm<UserFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      ...initialValues,
    },
    resolver: userResolver,
  })

  const handleClose = useCallback(() => {
    clearRedirect()
    reset({...initialValues}, {keepErrors: false, keepValues: false})
    onClose()
  }, [clearRedirect, onClose, reset])

  return (
    <AppDialog
      maxWidth="sm"
      open={open}
      title={t('welcome')}
      onClose={handleClose}
    >
      <AppButton
        variant="contained"
        color="primary"
        onClick={() => setModal('signup')}
        fullWidth
        type="submit"
        id="sign_up_by_email"
      >
        {t('create_an_account')}
      </AppButton>
      <AppButton
        variant="contained"
        fullWidth
        type="submit"
        onClick={() => setModal('signin')}
        style={{
          marginTop: 20,
        }}
      >
        {t('continue_with_email')}
      </AppButton>
      <AppDivider className="my-9">{t('or_continue_with')}</AppDivider>
      <SocialLogin />
    </AppDialog>
  )
}
