import {SET_DOCUMENT_WIDTH, SET_DOCUMENT_HEIGHT} from 'src/store/actionTypes'

export const setDocumentWidth = (width: number) => {
  return {
    type: SET_DOCUMENT_WIDTH,
    payload: width,
  }
}

export const setDocumentHeight = (height: number) => {
  return {
    type: SET_DOCUMENT_HEIGHT,
    payload: height,
  }
}
