import {
  createContext,
  useContext,
  useState,
  useEffect,
  SetStateAction,
} from 'react'
import {useRouter} from 'next/router'
import {useDispatch} from 'react-redux'
import {getMeAction} from 'src/store/actions/userAction'
import {useIsLoggedIn} from 'src/hooks/user'
import {ModalType} from 'src/types/modal'
import {PageProps} from 'src/types/page'
import {useRedirectContext} from './RedirectProvider'
import {HOME_PAGE} from 'src/constants/route'
import {CookieConsentSettingsType} from 'src/types/cookies'
import {getCookiesConsentSettingsClientSide} from 'src/utils/cookieManager'
import CookiesConsentPopup from 'src/components/modules/dialog/cookiesConsentPopup/CookiesConsentPopup'
import Cookies from 'js-cookie'

import LoadingBackdrop from 'src/components/elements/LoadingBackdrop'
import ActiveModal from 'src/components/modules/ActiveModal'

let cookieConsentSettingsInitial: CookieConsentSettingsType | null =
  getCookiesConsentSettingsClientSide()

const currentCookieConsentVersion: string = '1.0.3'
const cookieConsentDefaultValues: CookieConsentSettingsType = {
  version: currentCookieConsentVersion,
  showPopup: true, //dont show popup as of right now
  lastUpdatedAt: null,
  consentEssentials: true,
  consentAnalytics: false,
  consentMarketings: false,
  consentStatsCollection: false,
}

type AppContextProps = {
  loading: boolean
  setLoading: (loading: boolean) => void
  setModal: (modal: ModalType) => void
  openDateRangePicker: boolean
  setOpenDateRangePicker: (openDateRangePicker: boolean) => void
  currentPageBuilderPage: PageProps | null
  setCurrentPageBuilderPage: (page: PageProps | null) => void
  cookieConsentSettings: CookieConsentSettingsType
  setCookieConsentSettings: (
    settings: SetStateAction<CookieConsentSettingsType>,
  ) => void
}

export const AppContext = createContext<AppContextProps | undefined>(undefined)

AppContext.displayName = 'AppContext'

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (context === undefined)
    throw new Error(`${AppContext.displayName} must be used within provider`)

  return context
}

export default function AppProvider(props: {children: React.ReactChild}) {
  const [loading, setLoading] = useState(false)
  const [currentPageBuilderPage, setCurrentPageBuilderPage] =
    useState<PageProps | null>(null)
  const [modal, setModal] = useState<ModalType>('none')
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false)

  //only grab initial if the version match
  const [cookieConsentSettings, setCookieConsentSettings] =
    useState<CookieConsentSettingsType>(
      cookieConsentSettingsInitial
        ? cookieConsentSettingsInitial.version === currentCookieConsentVersion
          ? cookieConsentSettingsInitial
          : cookieConsentDefaultValues
        : cookieConsentDefaultValues,
    )

  const router = useRouter()
  const token = router.query.token
  const isLoggedIn = useIsLoggedIn()
  const dispatch = useDispatch()
  const {loginRequired} = useRedirectContext()

  useEffect(() => {
    if (loginRequired && !isLoggedIn && router.pathname === HOME_PAGE) {
      setModal('question')
    }
  }, [isLoggedIn, loginRequired, router.pathname])

  useEffect(() => {
    if (token) {
      setModal('reset-password')
    }
  }, [token])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getMeAction())
    }
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    //save to cookies
    Cookies.set('cookiesConsentSettings', JSON.stringify(cookieConsentSettings))
  }, [cookieConsentSettings])

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        setModal,
        openDateRangePicker,
        setOpenDateRangePicker,
        currentPageBuilderPage,
        setCurrentPageBuilderPage,
        cookieConsentSettings,
        setCookieConsentSettings,
      }}
    >
      {/* Make sure to stop rendering when not loading*/}
      {loading && <LoadingBackdrop open={loading} />}
      <ActiveModal modal={modal} setModal={setModal} />
      {props.children}
      <CookiesConsentPopup />
    </AppContext.Provider>
  )
}
