export const BASE_URL = process.env.NEXT_PUBLIC_APP_URL ?? ''
export const ADD_LISTING_PAGE = '/add-listing'
export const HOME_PAGE = '/'

//if we change url, we need to change backend too

export const ADMIN_PAGE = '/admin'

export const ADMIN_GUEST_MESSAGES_PAGE = '/admin/messages'
export const ADMIN_GUEST_FAVORITES_PAGE = '/admin/favorites'
export const ADMIN_GUEST_BOOKINGS_PAGE = '/admin/bookings'

export const ADMIN_INBOX_PAGE = '/admin/inbox'
export const ADMIN_RESERVATIONS_PAGE = '/admin/reservations'
export const ADMIN_CREATE_RESERVATION_PAGE = '/admin/reservations/create'
export const ADMIN_PROPERTIES_PAGE = '/admin/properties'
export const ADMIN_USERS_PAGE = '/admin/users'
export const ADMIN_REVIEWS_PAGE = '/admin/reviews'
export const ADMIN_CALENDAR_PAGE = '/admin/calendar'
export const ADMIN_STATS_PAGE = '/admin/stats'

export const ADMIN_ACCOUNT_PAGE = '/admin/account'

export const ADD_NEW_PROPERTY_PAGE = '/add-listing/one/accept-conditions'

export const SEARCH_RESULT_PAGE_EN = '/search'
export const SEARCH_RESULT_PAGE = '/recherche'

export const PROPERTY_PAGE_COTTAGE_RENTAL = 'cottage-rental'
export const PROPERTY_PAGE_CHALETS_A_LOUER = 'chalets-a-louer'
export const AUTH_PAGE = '/auth'

export const FAVORITE_PAGE_EN = '/cottage-rental/our-favorites'
export const FAVORITE_PAGE_FR = '/chalets-a-louer/nos-coups-de-coeur'
