import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(
  ({breakpoints, spacing}) => ({
    root: {
      display: 'flex',
      paddingBottom: spacing(3),
      paddingTop: spacing(3),
      [breakpoints.down('xs')]: {
        paddingBottom: spacing(2),
        paddingTop: spacing(2),
      },
    },
    primaryBtn: {
      height: spacing(6),
      width: '100%',
      marginLeft: 'auto',
      [breakpoints.up('sm')]: {
        maxWidth: spacing(33),
      },
    },
    secondaryBtn: {
      height: spacing(6),
      width: spacing(13.125),
      [breakpoints.down('xs')]: {
        marginRight: spacing(2),
      },
    },
  }),
  {index: 1},
)
