import {AnyAction} from 'redux'
import {SET_DOCUMENT_HEIGHT, SET_DOCUMENT_WIDTH} from 'src/store/actionTypes'

type StateType = {
  documentWidth: number | undefined
  documentHeight: number | undefined
}

const initialState: StateType = {
  documentWidth: undefined, //typeof window !== 'undefined' ? window.innerWidth : undefined,
  documentHeight: undefined, //typeof window !== 'undefined' ? window.innerHeight : undefined,
}

const globalReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_DOCUMENT_WIDTH: {
      return {
        ...state,
        documentWidth: action.payload,
      }
    }
    case SET_DOCUMENT_HEIGHT: {
      return {
        ...state,
        documentHeight: action.payload,
      }
    }
    default:
      return state
  }
}

export default globalReducer
