export const ERRORS = {
  OTHER: 'OTHER',
  NO_DATA: 'NO_DATA',
}

//baie st paul location
export const INITIAL_CENTER = {
  lat: 46.428169,
  lng: -72.822079,
}

export const INITIAL_ZOOM_LEVEL = 6
export const LOCATION_ZOOM_LEVEL = 10

export const LOCATION_PROPERTY_ZOOM_LEVEL = 17
