import {breakpoints} from 'src/components/materialTheme/breakpoints'
import {useSelector} from 'react-redux'
import {documentWidthSelector} from 'src/store/selectors/globalSelectors'

export const useSmallerSize = () => {
  const documentWidth = useSelector(documentWidthSelector)
  if (!documentWidth) return false //todo undefined
  return documentWidth < breakpoints.sm
}

export const useSmallSize = () => {
  const documentWidth = useSelector(documentWidthSelector)
  if (!documentWidth) return false //todo undefined
  return documentWidth < breakpoints.md
}

export const useMediumSize = () => {
  const documentWidth = useSelector(documentWidthSelector)
  if (!documentWidth) return false //todo undefined
  return documentWidth < breakpoints.lg
}

export const useLargeSize = () => {
  const documentWidth = useSelector(documentWidthSelector)
  if (!documentWidth) return false //todo undefined
  return documentWidth < breakpoints.xl
}

export const useXLargeSize = () => {
  const documentWidth = useSelector(documentWidthSelector)
  if (!documentWidth) return false //todo undefined
  return documentWidth >= breakpoints.xl
}

export const useScreenSizeDown = (
  keyOrNumber: keyof typeof breakpoints | number,
) => {
  const documentWidth = useSelector(documentWidthSelector)
  if (!documentWidth) return false //todo undefined

  if (typeof keyOrNumber === 'number') {
    return documentWidth < keyOrNumber
  }
  return documentWidth < breakpoints[keyOrNumber]
}

export const useScreenSizeUp = (
  keyOrNumber: keyof typeof breakpoints | number,
) => {
  const documentWidth = useSelector(documentWidthSelector)
  if (!documentWidth) return false
  if (typeof keyOrNumber === 'number') {
    return documentWidth >= keyOrNumber
  }
  return documentWidth >= breakpoints[keyOrNumber]
}

export const useBreakpoint = (): keyof typeof breakpoints | null => {
  const documentWidth = useSelector(documentWidthSelector)
  if (!documentWidth) return null
  let breakpoint: keyof typeof breakpoints = 'xs'
  if (!documentWidth) return breakpoint

  for (let key in breakpoints) {
    //@ts-ignore
    if (documentWidth >= breakpoints[key]) {
      breakpoint = key as keyof typeof breakpoints
    }
  }

  return breakpoint
}
