export default function Hidden(props: {
  when?: boolean
  children: React.ReactElement
}) {
  const {when, children} = props

  if (when) {
    return null
  }

  return <>{children}</>
}
