import {SET_BOOKING_BADGE, BADGE_UPDATED} from 'src/store/actionTypes'

export const setBookingBadgeAction = (payload: any) => {
  return {
    type: SET_BOOKING_BADGE,
    payload,
  }
}

export const setBadgeUpdatedAction = () => {
  return {
    type: BADGE_UPDATED,
  }
}
