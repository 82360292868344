import {SvgIconProps} from '@material-ui/core/SvgIcon'
import Close from '@material-ui/icons/Close'

const CloseIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <Close fontSize="inherit" className="cursor-pointer text-base" {...props} />
  )
}

export default CloseIcon
