import {
  LoginUserBody,
  RegisterUserBody,
  PhoneUserBody,
  PhoneVerify,
} from 'src/types/auth'
import {http} from 'src/services/http'
import {ChangePasswordData} from 'src/types/changePassword'
import {UserPhoneNumber} from 'src/types/user'
import {CancelToken} from 'axios'
import {Dayjs} from 'src/utils/date'

export async function publicSocialLoginApi(body: any) {
  const {data} = await http.post('/public/social_login', body)
  return data
}

export async function socialConnectApi(body: any) {
  const {data} = await http.post('/social_connect', body)
  return data
}

export async function deleteSocialConnectApi(body: any) {
  const {data} = await http.delete(`/social_connect/${body}`)
  return data
}

export async function removeUserImageApi() {
  const {data} = await http.delete('/user/remove_image')
  return data
}

export async function adminCreateUserApi(body: any) {
  const {data} = await http.post('/admin/users', body)
  return data
}

export async function adminUpdateUserApi(userId: number, body: any) {
  const {data} = await http.put(`/admin/users/${userId}`, body)
  return data
}

export async function publicRegisterUserApi(body: RegisterUserBody) {
  const {data} = await http.post('/public/register', body)
  return data
}

//todo security issue
export async function publicCheckIfEmailIsAvailableApi(
  email: string,
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'post',
    url: '/public/check_email',
    data: {
      email,
    },
    cancelToken,
  })

  return data
}

export async function publicCheckIfPasswordIsValidApi(
  password: string,
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'post',
    url: '/public/check_password',
    data: {
      password,
    },
    cancelToken,
  })

  return data
}

export async function updateUserApi(body: FormData | any) {
  const data = await http.post('/user', body)
  return data
}

export async function publicVerifyUserApi(token: string) {
  const data = await http.get(`/public/email/verify/${token}`)
  return data
}

export async function publicVerifyUserOnboardingTokenApi(token: string) {
  const data = await http.get(`/public/onboarding/verify/${token}`)
  return data
}

export async function publicCompletedUserOnboardingTokenApi(token: string) {
  const data = await http.post(`/public/onboarding/completed/${token}`)
  return data
}

export async function publicGetMeFromTokenApi(token: string) {
  const {data} = await http.get(`/public/me/token/${token}`)
  return data
}

export async function publicUpdateMeFromTokenApi(token: string, values: any) {
  const {data} = await http.post(`/public/me/token/${token}`, values)
  return data
}

export async function getMeApi() {
  const {data} = await http.get(`/me`)
  return data
}

export async function getStats(startDate: Dayjs | null, endDate: Dayjs | null) {
  const {data} = await http.get(`/stats`, {
    params: {
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
    },
  })
  return data
}

export async function publicResetPasswordRequestApi(email: string) {
  const data = await http.post('/public/reset_password', {
    email,
  })
  return data
}

export async function publicUpdateUserPasswordByTokenApi(
  password: string,
  confirm_password: string,
  token: string,
) {
  const data = await http.post(`/public/reset_password/${token}`, {
    password,
    password_confirmation: confirm_password,
  })
  return data
}

export async function publicUpdateUserPasswordByOnboardingTokenApi(
  password: string,
  confirm_password: string,
  token: string,
) {
  const data = await http.post(`/public/onboarding/reset_password/${token}`, {
    password,
    password_confirmation: confirm_password,
  })
  return data
}

export async function publicLoginUserApi(body: LoginUserBody) {
  const {data} = await http.post('/public/login', body)
  return data
}

export async function logoutUserApi() {
  const {data} = await http.post('/logout')
  return data
}

export async function addUserPhoneNumberApi(body: PhoneUserBody) {
  const {data} = await http.post('/user/phone_number', body)
  return data as UserPhoneNumber[]
}

export async function updatePhoneNumberApi(datas: PhoneUserBody, id: number) {
  const {data} = await http.put(`/user/phone_number/${id}`, datas)
  return data
}

export async function resendVerificationApi(phoneNumber: string) {
  const {data} = await http.post('/user/phone_number/resend', {
    phone_number: phoneNumber,
  })
  return data
}

export async function verifyPhoneNumberApi(token: string, phoneNumber: string) {
  const {data} = await http.post('/user/phone_number/verify', {
    phone_number: phoneNumber,
    token,
  })
  return data
}

export async function getUserPhoneNumbersApi() {
  const data = await http.get('/user/phone_numbers')
  return data
}

export async function updateCurrentPasswordApi(password: ChangePasswordData) {
  const data = await http.post('/update_password', password)
  return data
}

export async function verifyPhoneUserApi(datas: PhoneVerify) {
  const data = await http.post('/user/phone_number/verify', datas)
  return data
}

export async function setHostApi() {
  const {data} = await http.put('/user/set_host')
  return data
}

export async function setUseFacebookPhotoApi() {
  const {data} = await http.put(`/user/use_facebook_photo`)

  return data
}

export async function publicLoginByTokenApi(token: string) {
  const {data} = await http.post(`/public/login_by_token`, {token})

  return data
}
