import {DEFAULT_LOCALE, LOCALE_EN, LOCALE_FR} from 'src/constants/locale'
import {
  HOME_PAGE,
  PROPERTY_PAGE_CHALETS_A_LOUER,
  PROPERTY_PAGE_COTTAGE_RENTAL,
} from 'src/constants/route'
import {PageProps} from 'src/types/page'

const CUSTOM_MAP_PATHS: {[key: string]: string} = {
  '/recherche': '/en/search',
  '/en/search': '/recherche',

  '/politiques-dannulation': '/en/cancellation-policies',
  '/en/cancellation-policies': '/politiques-dannulation',

  '/aproposdenous': '/en/about-us',
  '/en/about-us': '/aproposdenous',

  '/carrieres': '/en/career',
  '/en/career': '/carrieres',

  '/contacteznous': '/en/contact-us',
  '/en/contact-us': '/contacteznous',

  '/chalets-a-vendre': '/en/chalets-for-sale',
  '/en/chalets-for-sale': '/chalets-a-vendre',

  '/conditions-d-utilisation': '/en/terms-of-use',
  '/en/terms-of-use': '/conditions-d-utilisation',

  '/politique-de-confidentialite': '/en/privacy-policy',
  '/en/privacy-policy': '/politique-de-confidentialite',
}

export function convertPathnameWithLocale(
  pathname: string,
  currentLocale: string,
  localeWanted: string,
  options: {
    currentPage: PageProps | null
  } = {
    currentPage: null,
  },
) {
  const {currentPage} = options

  //from / to /en
  if (pathname === HOME_PAGE && currentLocale === DEFAULT_LOCALE) {
    return {
      differentTemplate: false,
      newAsPath: `/${localeWanted}`,
    }
  }

  //from /en to /
  if (pathname === `/${currentLocale}` && currentLocale !== DEFAULT_LOCALE) {
    return {
      differentTemplate: false,
      newAsPath: HOME_PAGE,
    }
  }

  if (currentPage) {
    const connectedPagesWithLocale = currentPage.other_lang_pages.find(
      (p) => p.locale === localeWanted,
    )

    if (connectedPagesWithLocale) {
      return {
        differentTemplate: true,
        newAsPath: connectedPagesWithLocale.pathname_url,
      }
    }

    if (!connectedPagesWithLocale) {
      return {
        differentTemplate: true,
        newAsPath: localeWanted === LOCALE_EN ? '/en/404' : '/404',
      }
    }
  }

  //property checkup
  if (
    currentLocale === LOCALE_FR &&
    pathname.startsWith(`/${PROPERTY_PAGE_CHALETS_A_LOUER}`) &&
    localeWanted === LOCALE_EN
  ) {
    return {
      differentTemplate: false,
      newAsPath: pathname.replace(
        `/${PROPERTY_PAGE_CHALETS_A_LOUER}`,
        `/en/${PROPERTY_PAGE_COTTAGE_RENTAL}`,
      ),
    }
  }
  //property checkup
  if (
    currentLocale === LOCALE_EN &&
    pathname.startsWith(`/en/${PROPERTY_PAGE_COTTAGE_RENTAL}`) &&
    localeWanted === LOCALE_FR
  ) {
    return {
      differentTemplate: false,
      newAsPath: pathname.replace(
        `/en/${PROPERTY_PAGE_COTTAGE_RENTAL}`,
        `/${PROPERTY_PAGE_CHALETS_A_LOUER}`,
      ),
    }
  }

  //key find
  if (typeof CUSTOM_MAP_PATHS[pathname] !== 'undefined') {
    return {
      differentTemplate: false,
      newAsPath: CUSTOM_MAP_PATHS[pathname], //return  value
    }
  }

  let newPathname = ''
  if (localeWanted === DEFAULT_LOCALE) {
    newPathname = pathname.replace(`/${LOCALE_EN}/`, '/')
  }

  if (localeWanted !== DEFAULT_LOCALE) {
    newPathname = `/${localeWanted}${pathname}`
  }

  return {
    differentTemplate: false,
    newAsPath: newPathname,
  }
}
