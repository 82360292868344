import {AnyAction} from 'redux'
import {SET_BOOKING_BADGE, BADGE_UPDATED} from 'src/store/actionTypes'

const initialState: any = {
  host_badge: 0,
  guest_badge: 0,
  badge_updated: false,
}

const badgeReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_BOOKING_BADGE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case BADGE_UPDATED: {
      return {
        ...state,
        badge_updated: !state.badge_updated,
      }
    }
    default:
      return state
  }
}

export default badgeReducer
