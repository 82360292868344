import isEmpty from 'validator/lib/isEmpty'
import isEmail from 'validator/lib/isEmail'
import isLength from 'validator/lib/isLength'
import matches from 'validator/lib/matches'
import isStrongPassword from 'validator/lib/isStrongPassword'

import {months} from 'src/utils/data'

export function validateAcceptTerms(value: string) {
  if (!value) {
    return 'Accepting terms and conditions is required'
  }
  return undefined
}
export function validateEmail(value: string) {
  if (isEmpty(value)) {
    return `Email is required`
  }

  if (!isEmail(value)) {
    return 'Email is not valid'
  }

  return undefined
}

export function validateName(field: string) {
  return (value: string) => {
    if (isEmpty(value)) {
      return `${field} is required`
    }

    if (!matches(value, /^[a-zA-ZÀ-ÿ-. ]*$/)) {
      return `${field} should only contain letters`
    }

    if (!isLength(value, {max: 50})) {
      return `${field} should be less than 50 letters`
    }

    return undefined
  }
}

export function validateRequired(field: string) {
  return (value: any) => {
    if (isEmpty(value)) {
      return `${field} is required`
    }

    return undefined
  }
}

export function validateConfirmPassword(value: string, match: string) {
  if (!value) {
    return 'Password is required'
  }

  if (value !== match) {
    return 'Password do not match'
  }

  return undefined
}

export function validateDay(value: string) {
  if (!value) {
    return 'Day is required'
  }

  const intValue = parseInt(value)
  if (intValue > 31 || intValue < 0) {
    return 'Day is not valid'
  }

  return undefined
}

export function validatePassword(value: string) {
  if (!value) {
    return 'Password is required'
  }

  if (!isLength(value, {min: 8})) {
    return 'Password should be at least 8 characters'
  }

  if (
    !isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    })
  ) {
    return 'weak_password'
  }

  return undefined
}

export function validateLoginPassword(value: string) {
  if (!value) {
    return 'Password is required'
  }

  if (!isLength(value, {min: 8})) {
    return 'Password should be at least 8 characters'
  }

  return undefined
}

export function validateLanguage(_: string) {
  return undefined
}

export function validateMonth(value: string) {
  if (!value) {
    return 'Month is required'
  }

  if (!months.includes(value)) {
    return 'Month is not valid'
  }

  return undefined
}

export function validateYear(value: string) {
  if (!value) {
    return 'Year is required'
  }

  return undefined
}
