import 'dayjs/locale/fr-ca'
import 'dayjs/locale/en-ca'
import pluginUtc from 'dayjs/plugin/utc'
import pluginIsoWeek from 'dayjs/plugin/isoWeek'
import pluginIsBetween from 'dayjs/plugin/isBetween'
import pluginIsSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import pluginIsSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import pluginLocalizedFormat from 'dayjs/plugin/localizedFormat'
import pluginCustomParseFormat from 'dayjs/plugin/customParseFormat'
import pluginPluralGetSet from 'dayjs/plugin/pluralGetSet'
import {default as dayjsModule} from 'dayjs'
import {Dayjs as DayjsType} from 'dayjs'
import {LOCALE_EN} from 'src/constants/locale'
import {ucFirst} from './other'
import {LocaleType} from 'src/types/utils'

dayjsModule.extend(pluginUtc)
dayjsModule.extend(pluginIsoWeek)
dayjsModule.extend(pluginIsBetween)
dayjsModule.extend(pluginIsSameOrAfter)
dayjsModule.extend(pluginIsSameOrBefore)
dayjsModule.extend(pluginLocalizedFormat)
dayjsModule.extend(pluginCustomParseFormat)
dayjsModule.extend(pluginPluralGetSet)

if (process.env.NODE_ENV !== 'production') {
  dayjsModule.extend(require('dayjs/plugin/devHelper'))
}

//init with locale
if (typeof window !== 'undefined') {
  const initLocale = window.__NEXT_DATA__.locale || 'fr'
  //@ts-ignore
  dayjsModule.locale(`${initLocale}-ca`)
}

export let dayjs = dayjsModule
export type Dayjs = DayjsType

export function setDayJsLocale(locale: LocaleType) {
  let finalLocale = `${locale.toString().toLowerCase()}-ca`
  dayjs.locale(finalLocale)
}

export function getDateDifferences(
  start: string | null | Dayjs,
  end: string | null | Dayjs,
) {
  if (!start || !end) {
    return 0
  }

  //remove hours by marking as starting days so we get real day diff and not block in 24h period
  const startDate = dayjs(start).startOf('day')
  const endDate = dayjs(end).startOf('day')

  return Math.abs(startDate.diff(endDate, 'days'))
}

export function getFormattedDate(date: string | Dayjs, locale: LocaleType) {
  if (locale === LOCALE_EN) {
    return dayjs(date).format('ddd MMM D YYYY')
  }

  const day = dayjs(date).format('ddd')

  const dateStr = dayjs(date).format('D')

  const month = dayjs(date).format('MMM')

  const year = dayjs(date).format('YYYY')

  return `${ucFirst(day)} ${dateStr} ${ucFirst(month)} ${year}`
}
