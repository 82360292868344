import {useRouter} from 'next/router'
import {createContext, useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import LoadingBackdrop from 'src/components/elements/LoadingBackdrop'
import {usePrevious} from 'src/hooks/other'
import {loginByTokenAction} from 'src/store/actions/userAction'
import {loginStatusSelector} from 'src/store/selectors/userSelector'

interface LoginContextProps {}

export const LoginContext = createContext<LoginContextProps | undefined>(
  undefined,
)

LoginContext.displayName = 'LoginContext'

export const useLoginContext = () => {
  const context = useContext(LoginContext)
  if (context === undefined)
    throw new Error(`${LoginContext.displayName} must be used within provider`)

  return context
}

export default function LoginProvider(props: {children: React.ReactChild}) {
  const dispatch = useDispatch()
  const router = useRouter()
  const token = router.query.login_token

  const loginStatus = useSelector(loginStatusSelector)
  const loading = loginStatus.loading
  const loginByTokenStatus = loginStatus.success
  const previousLoginByTokenStatus = usePrevious(loginByTokenStatus)

  useEffect(() => {
    if (token) {
      dispatch(loginByTokenAction({token: token as string}))
    }
  }, [dispatch, token])

  useEffect(() => {
    if (!token) {
      return
    }

    if (loginByTokenStatus && !previousLoginByTokenStatus) {
      let newQuery = {
        ...router.query,
      }
      // login success
      delete newQuery.login_token
      router.replace({pathname: router.pathname, query: newQuery}, undefined, {
        shallow: false,
      })
    }
  }, [loginByTokenStatus, previousLoginByTokenStatus, router, token])

  if (loading && token) {
    return <LoadingBackdrop open />
  }

  return (
    <LoginContext.Provider value={{}}>{props.children}</LoginContext.Provider>
  )
}
