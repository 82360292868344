import {useRouter} from 'next/router'
import {LocaleType} from 'src/types/utils'

export function useCurrentLocale() {
  const {locale} = useRouter()
  // const [currentLocale] = (locale as string).split('-')
  if (!locale) {
    return 'fr' as LocaleType //default
  }

  return locale as LocaleType
}
