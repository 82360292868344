import {useTranslation} from 'next-i18next'
import AppModal from 'src/components/elements/AppModal'
import AppTypography from 'src/components/elements/typography/AppTypography'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'

export default function EmailVerified(props: {
  open: boolean
  onClose: () => void
}) {
  const {open, onClose} = props

  const {t} = useTranslation('common')

  return (
    <AppModal
      title={t('Email successfully verified')}
      open={open}
      handleClose={onClose}
    >
      <div className="flex flex-col gap-1">
        <AppTypography variant="body" neutralColor={600}>
          {t('verify_success_message')}
        </AppTypography>
        <AppRoundedButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={onClose}
        >
          {t('close')}
        </AppRoundedButton>
      </div>
    </AppModal>
  )
}
