import {useRouter} from 'next/router'
import {createContext, useCallback, useContext, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {ACCESS_OVERVIEW} from 'src/constants/collaboratorAccess'
import {
  ADMIN_ACCOUNT_PAGE,
  ADMIN_GUEST_BOOKINGS_PAGE,
  ADMIN_GUEST_FAVORITES_PAGE,
  ADMIN_GUEST_MESSAGES_PAGE,
  ADMIN_INBOX_PAGE,
  ADMIN_PAGE,
  ADMIN_PROPERTIES_PAGE,
  ADMIN_RESERVATIONS_PAGE,
} from 'src/constants/route'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_GUEST,
  USER_ROLE_HOST,
} from 'src/constants/userRole'
import {usePrevious, useRouterLocationPathname} from 'src/hooks/other'
import {useHasAccess} from 'src/hooks/permissionAndAccess'
import {useUserInfo, useIsGuestMenu, useIsLoggedIn} from 'src/hooks/user'
import {setMenuAction} from 'src/store/actions/userAction'

interface MenuContextProps {
  toggleMenu: () => void
}

export const MenuContext = createContext<MenuContextProps | undefined>(
  undefined,
)

MenuContext.displayName = 'MenuContext'

export const useMenuContext = () => {
  const context = useContext(MenuContext)
  if (context === undefined)
    throw new Error(`${MenuContext.displayName} must be used within provider`)

  return context
}

export default function MenuProvider(props: {children: React.ReactChild}) {
  const dispatch = useDispatch()
  const router = useRouter()
  const userInfo = useUserInfo()
  const isGuestMenu = useIsGuestMenu()
  const isPreviousGuestMenu = usePrevious(isGuestMenu)
  const cleanPathname = useRouterLocationPathname()
  const isLoggedIn = useIsLoggedIn()
  const isPreviousLoggedIn = usePrevious(isLoggedIn)
  const hasAccessOverview = useHasAccess(ACCESS_OVERVIEW)

  const toggleMenu = useCallback(() => {
    const newMenu: 'host' | 'guest' = isGuestMenu ? 'host' : 'guest'

    dispatch(setMenuAction(newMenu))

    const isAdminPage = cleanPathname.startsWith(ADMIN_PAGE)
    const shouldNotReplaceRoute =
      !isAdminPage ||
      (cleanPathname === ADMIN_GUEST_MESSAGES_PAGE && !isGuestMenu) ||
      (cleanPathname === ADMIN_INBOX_PAGE && isGuestMenu)

    if (shouldNotReplaceRoute) {
      return
    }

    //redirect to base page of admin page
    if (newMenu === 'host' && hasAccessOverview) {
      router.replace(ADMIN_PAGE, undefined, {shallow: true})
      return
    }

    router.replace(ADMIN_ACCOUNT_PAGE, undefined, {shallow: true})
  }, [cleanPathname, dispatch, hasAccessOverview, isGuestMenu, router])

  useEffect(() => {
    if (!isLoggedIn || !userInfo) {
      return
    }

    // if admin then should be always host menu
    if (userInfo.role === USER_ROLE_ADMIN && isGuestMenu) {
      toggleMenu()
      return
    }

    if (isGuestMenu !== isPreviousGuestMenu) {
      // if switch menu action then ignore menu change
      return
    }

    // check admin paths
    if (cleanPathname === ADMIN_GUEST_MESSAGES_PAGE && !isGuestMenu) {
      toggleMenu()
      return
    }

    if (cleanPathname === ADMIN_INBOX_PAGE && isGuestMenu) {
      toggleMenu()
      return
    }

    if (cleanPathname === ADMIN_GUEST_FAVORITES_PAGE && !isGuestMenu) {
      toggleMenu()
      return
    }

    if (cleanPathname === ADMIN_GUEST_BOOKINGS_PAGE && !isGuestMenu) {
      toggleMenu()
      return
    }

    if (cleanPathname === ADMIN_RESERVATIONS_PAGE && isGuestMenu) {
      toggleMenu()
      return
    }

    if (cleanPathname === ADMIN_PROPERTIES_PAGE && isGuestMenu) {
      toggleMenu()
      return
    }

    // if not admin paths and when log in check userinfo role and switch menu
    if (isLoggedIn === isPreviousLoggedIn) {
      return
    }

    if (userInfo.role === USER_ROLE_HOST && isGuestMenu) {
      toggleMenu()
      return
    }

    if (userInfo.role === USER_ROLE_GUEST && !isGuestMenu) {
      toggleMenu()
      return
    }
  }, [
    cleanPathname,
    isGuestMenu,
    isPreviousGuestMenu,
    toggleMenu,
    isLoggedIn,
    isPreviousLoggedIn,
    userInfo?.role,
    userInfo,
  ])

  return (
    <MenuContext.Provider value={{toggleMenu}}>
      {props.children}
    </MenuContext.Provider>
  )
}
