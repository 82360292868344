import InputBase, {InputBaseProps} from '@material-ui/core/InputBase'
import {makeStyles} from '@material-ui/core/styles'
import {interFontFamily} from 'src/components/materialTheme/typography'

const useStyles = makeStyles(({palette, spacing}) => ({
  root: {
    'label + &': {
      marginTop: spacing(0.5),
    },
  },
  input: {
    borderRadius: `3px !important`,
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.divider}`,
    fontSize: 14,
    padding: spacing(1.5, 2.5),
    fontFamily: interFontFamily,
    '&:focus': {
      borderWidth: 1.5,
    },
    '&[aria-invalid="true"]': {
      borderColor: palette.error.main,
    },
    '&:focus[aria-invalid="false"]': {
      borderColor: palette.primary.main,
    },
  },
}))

const EnhancedInput = (props: InputBaseProps) => {
  const classes = useStyles()
  return <InputBase classes={classes} {...props} />
}

export default EnhancedInput
