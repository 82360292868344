import {http} from 'src/services/http'

type DataCollectionType =
  | 'booking_checkout_completed'
  | 'booking_checkout_started'
  | 'not_signup_want_to_book'
  | 'view_unique_property_page'
  | 'property'
  | 'search'
  | 'login'
  | 'signup'
  | 'view_booking_page'

export async function publicAddDataCollectionApi(
  type: DataCollectionType,
  data?: Object,
) {
  const {data: result} = await http.post(`/public/data`, {
    type,
    data,
  })

  return result
}
