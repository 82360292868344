import {createSelector} from 'reselect'

const userDataSelector = (state: any) => state.user

export const userInfoSelector = createSelector(
  userDataSelector,
  (payload) => payload.login?.user,
)

export const loginStatusSelector = createSelector(
  userDataSelector,
  (payload) => payload.status,
)

export const currentMenuSelector = createSelector(
  userDataSelector,
  (payload) => payload.currentMenu,
)

export const isAdminSelector = createSelector(
  userDataSelector,
  (payload) => payload?.login?.user?.is_admin,
)

export const socialConnectErrorSelector = createSelector(
  userDataSelector,
  (payload) => payload?.social_connect_error,
)
