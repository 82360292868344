import {useState} from 'react'
import {signIn, signOut} from 'next-auth/client'
import {makeStyles} from '@material-ui/core/styles'
import AppButton from 'src/components/elements/buttons/AppButton'
import FacebookIcon from 'src/components/elements/icons/Facebook'
import AppIcon from 'src/components/elements/icons/AppIcon'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_FR} from 'src/constants/locale'
import {BASE_URL} from 'src/constants/route'
import {useRedirectContext} from 'src/context/RedirectProvider'

const redirectUrl = process.env.NEXT_PUBLIC_SOCIAL_LOGIN_REDIRECT_URL

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({palette}) => ({
    socialButton: {
      border: `1px solid ${palette.divider}`,
      color: `${palette.action.active} !important`,
    },
  }),
  {index: 1},
)

export default function SocialLogin() {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const currentLocale = useCurrentLocale()
  const {setRedirect} = useRedirectContext()

  const handleSocialLogin = (provider: 'google' | 'facebook') => () => {
    setRedirect()
    setLoading(true)
    signOut({redirect: false}).then(() => {
      signIn(provider, {
        callbackUrl:
          currentLocale === LOCALE_FR
            ? `${BASE_URL}${redirectUrl}`
            : `${BASE_URL}/${currentLocale}${redirectUrl}`,
        redirect: false,
      }).finally(() => setLoading(false))
    })
  }

  return (
    <div className="flex flex-row justify-start items-start gap-4">
      <AppButton
        startIcon={<FacebookIcon />}
        className={classes.socialButton}
        fullWidth
        onClick={handleSocialLogin('facebook')}
        id="sign_up_by_facebook"
        disabled={loading}
      >
        Facebook
      </AppButton>
      <AppButton
        startIcon={<AppIcon name="google-icon" />}
        className={classes.socialButton}
        fullWidth
        onClick={handleSocialLogin('google')}
        id="sign_up_by_google"
        disabled={loading}
      >
        Google
      </AppButton>
    </div>
  )
}
