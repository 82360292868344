import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(
  ({palette, spacing}) => ({
    regionDialogWrapper: {
      overflowY: 'auto',
    },
    socialButton: {
      border: `1px solid ${palette.divider}`,
      color: `${palette.action.active} !important`,
    },
    regionOptionsContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      marginTop: spacing(1.5),
      marginBottom: spacing(3),
    },
    regionItemContainer: {
      cursor: 'pointer',
      display: 'flex',
      flex: '0 0 50%',
      maxWidth: '50%',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: spacing(3, 2),
      background: palette.common.white,
      border: `1px solid ${palette.grey[200]}`,
      borderRadius: spacing(0.75),
      '& p': {
        display: 'flex',
        alignItems: 'center',
        color: palette.grey[600],
      },
      '&:last-child': {
        marginLeft: spacing(1),
      },
      '&:first-child': {
        marginRight: spacing(1),
      },
    },
    regionOptionsContainerSM: {
      flex: '0 0 100%',
      maxWidth: '100%',
      '&:last-child': {
        marginLeft: 0,
        marginTop: spacing(1.5),
      },
      '&:first-child': {
        marginRight: 0,
      },
    },
    flagIcon: {
      marginRight: spacing(1.75),
      color: palette.grey[600],
    },
    checkIcon: {
      width: spacing(1.5),
      height: spacing(1.5),
      color: palette.primary.main,
    },
    amenitiesButton: {
      display: 'flex',
      flex: ' 0 0 100%',
      maxWidth: '100%',
    },
  }),
  {index: 1},
)
