import {createTheme} from '@material-ui/core/styles'
import {breakpoints} from './breakpoints'
import {meadowColor, palette} from './palette'
import {props} from './props'
import {typography} from './typography'
import {globalZIndex} from 'src/utils/styles'

export const theme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  palette,
  props,
  typography,
  zIndex: {
    mobileStepper: globalZIndex.aboveBase,
    speedDial: globalZIndex.moreAboveBase,
    appBar: globalZIndex.header,
    drawer: globalZIndex.aboveHeader,
    modal: globalZIndex.modal,
    snackbar: globalZIndex.priority,
    tooltip: globalZIndex.abovePriority,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '12px 24px',
        '&$disabled': {
          background: `${palette.grey?.[100]} !important`,
          color: `${palette.grey?.[300]} !important`,
        },
      },
      outlined: {
        border: `1px solid ${palette.divider}`,
        padding: undefined,
      },
      sizeSmall: {
        padding: '4px 16px',
      },
    },
    MuiSvgIcon: {
      colorAction: {
        color: palette.text?.secondary,
      },
    },
    MuiChip: {
      root: {
        borderRadius: '48px',
        height: '24px',
      },
      outlinedPrimary: {
        border: 'none',
        background: `${meadowColor.light} !important`,
        color: meadowColor.dark,
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
})
