import {createContext, useContext, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Pusher from 'pusher-js/types/src/core/pusher'
import {createPusher} from 'src/utils/pusher'
import {setMessageBadgeAction} from 'src/store/actions/messageActions'
import {getBookingBadgeCountApi} from 'src/services/api/booking'
import {getMessageBadgesApi} from 'src/services/api/message'
import {
  setBookingBadgeAction,
  setBadgeUpdatedAction,
} from 'src/store/actions/badgeAction'
import {useUserInfo, useIsLoggedIn, useIsAdmin} from 'src/hooks/user'
import {bookingBadgeSelector} from 'src/store/selectors/badgeSelector'
import {Channel} from 'pusher-js'

interface PusherContextProps {
  pusher: Pusher | null
  badgeUpdated: boolean
}

export const PusherContext = createContext<PusherContextProps | undefined>(
  undefined,
)

PusherContext.displayName = 'PusherContext'

export const usePusherContext = () => {
  const context = useContext(PusherContext)
  if (context === undefined)
    throw new Error(`${PusherContext.displayName} must be used within provider`)

  return context
}

const BadgeUpdatedEvent = 'badge.updated'
const BookingsUpdatedEvent = 'bookings.updated'

export default function PusherProvider(props: {children: React.ReactChild}) {
  const [token, setToken] = useState<string | null>(null)
  const [pusherRef, setPusherRef] = useState<Pusher | null>(null)
  const [channelRef, setChannelRef] = useState<Channel | null>(null)

  const userInfo = useUserInfo()
  const dispatch = useDispatch()
  const isLoggedIn = useIsLoggedIn()
  const isAdmin = useIsAdmin()
  const bookingBadgeInfo = useSelector(bookingBadgeSelector)

  useEffect(() => {
    if (!isLoggedIn || !token) {
      return
    }

    const channelName = isAdmin
      ? `private-admin.${userInfo?.id}`
      : `private-badge.${userInfo?.id}`

    ;(async () => {
      const pusherInstance = await createPusher(token)
      if (!pusherInstance) return

      const channelInstance = pusherInstance.subscribe(channelName)

      if (isAdmin) {
        channelInstance.bind(BookingsUpdatedEvent, (data: any) => {
          dispatch(setMessageBadgeAction(data))
          dispatch(setBookingBadgeAction(data.booking_badge))
          dispatch(setBadgeUpdatedAction())
        })
      }

      if (!isAdmin) {
        channelInstance.bind(BadgeUpdatedEvent, (data: any) => {
          dispatch(setMessageBadgeAction(data))
          dispatch(setBookingBadgeAction(data.booking_badge))
          dispatch(setBadgeUpdatedAction())
        })
      }

      setChannelRef(channelInstance)
      setPusherRef(pusherInstance)
    })()

    return () => {
      if (pusherRef && channelRef) {
        pusherRef.unsubscribe(channelName)
        channelRef.unbind(BadgeUpdatedEvent)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch, isLoggedIn])

  useEffect(() => {
    if (!isLoggedIn || !token) {
      return
    }

    getMessageBadgesApi().then((data: any) =>
      dispatch(setMessageBadgeAction(data)),
    )
    getBookingBadgeCountApi().then((data: any) =>
      dispatch(setBookingBadgeAction(data)),
    )
  }, [dispatch, isLoggedIn, token])

  useEffect(() => {
    window.addEventListener('storage', () => {
      const token = localStorage.getItem('token')
      setToken(token)
    })

    setToken(localStorage.getItem('token'))
  }, [])

  return (
    <PusherContext.Provider
      value={{
        pusher: pusherRef,
        badgeUpdated: bookingBadgeInfo.badge_updated,
      }}
    >
      {props.children}
    </PusherContext.Provider>
  )
}
