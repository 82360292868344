import {dayjs, Dayjs} from 'src/utils/date'
import {useTranslation} from 'next-i18next'
import {LOCALE_EN} from 'src/constants/locale'
import {useCurrentLocale} from 'src/hooks/locale'
import {ucFirst} from 'src/utils/other'
import {LocaleType} from 'src/types/utils'
import {useMemo} from 'react'
import {months} from 'src/utils/data'

export function useFormattedDate(date: Dayjs | null, addTime = false) {
  const currentLocale = useCurrentLocale()

  return getFormattedDateWithTime(date, currentLocale, addTime)
}

export function getFormattedDateWithTime(
  date: Dayjs | null,
  currentLocale: LocaleType,
  addTime = false,
) {
  if (!date) {
    return null
  }

  const dayJsDate = dayjs(date)

  if (currentLocale === LOCALE_EN) {
    const result = dayJsDate.format(
      'ddd MMM D YYYY' + (addTime ? ' HH:mm' : ''),
    )

    return result
  }

  const day = dayJsDate.format('ddd')
  const dateStr = dayJsDate.format('D')
  const month = dayJsDate.format('MMM')
  const year = dayJsDate.format('YYYY')

  return `${ucFirst(day)} ${dateStr} ${ucFirst(month)} ${year}${
    addTime ? ` ${dayJsDate.format('HH:mm')}` : ''
  }`
}

export function getSimpleFormattedDate(
  date: Dayjs | null,
  currentLocale: LocaleType,
  addTime = false,
  removeYear = false,
  removeMonth = false,
) {
  if (!date) {
    return null
  }

  const dayJsDate = dayjs(date)

  if (currentLocale === LOCALE_EN) {
    const result = dayJsDate.format(
      `${removeMonth ? '' : 'MMM '}D${removeYear ? '' : ' YYYY'}${
        addTime ? ' HH:mm' : ''
      }`,
    )

    return result
  }

  const dateStr = dayJsDate.format('D')
  const month = dayJsDate.format('MMM')
  const year = dayJsDate.format('YYYY')

  return `${dateStr}${removeMonth ? '' : ` ${ucFirst(month)}`}${
    removeYear ? `` : ` ${year}`
  }${addTime ? ` ${dayJsDate.format('HH:mm')}` : ''}`
}

export function useFormattedDate1(date: Dayjs | null) {
  const currentLocale = useCurrentLocale()

  if (!date) {
    return null
  }

  if (currentLocale === LOCALE_EN) {
    return dayjs(date).format('MMM D YYYY')
  }

  const dateStr = dayjs(date).format('D')

  const month = dayjs(date).format('MMM')

  const year = dayjs(date).format('YYYY')

  return `${dateStr} ${ucFirst(month)} ${year}`
}

export function useFormattedTime(time: string) {
  return dayjs(time, 'HH:mm').format('h:mm A')
}

export function useFormattedNights(
  start: string | null | Dayjs,
  end: string | null | Dayjs,
) {
  const {t} = useTranslation('common')

  const nights = useDateDifferences(start, end)

  if (nights > 1) {
    return `${nights} ${t('night')}s`
  }

  return `${nights} ${t('night')}`
}

export function useDateDifferences(
  start: string | null | Dayjs,
  end: string | null | Dayjs,
) {
  if (!start || !end) {
    return 0
  }

  //remove hours by marking as starting days so we get real day diff and not block in 24h period
  const startDate = dayjs(start).startOf('day')
  const endDate = dayjs(end).startOf('day')

  return Math.abs(startDate.diff(endDate, 'days'))
}

export function useIsAfter(start: string | Dayjs, end: string | Dayjs) {
  return dayjs(start).isAfter(dayjs(end))
}

export function useDateOptions() {
  const {t} = useTranslation('common')

  const pastYearOptions = useMemo(() => {
    return Array.from({length: dayjs().year() - 1910 + 1})
      .map((_, index) => ({
        label: `${1910 + index}`,
        value: `${1910 + index}`,
      }))
      .reverse()
  }, [])

  const futureYearOptions = useMemo(() => {
    let startYear = dayjs().subtract(1, 'year').year()
    return Array.from({length: 20})
      .map((_, index) => ({
        label: `${startYear + index}`,
        value: `${startYear + index}`,
      }))
      .reverse()
  }, [])

  const dayOptions = useMemo(() => {
    return Array.from({length: 31}).map((_, index) => ({
      label: `${index + 1}`,
      value: `${index + 1}`,
    }))
  }, [])

  const monthOptions = useMemo(() => {
    return months.map((month) => ({label: t(month), value: month}))
  }, [t])

  return {
    pastYearOptions,
    futureYearOptions,
    dayOptions,
    monthOptions,
  }
}
