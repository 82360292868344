import {MessageCustomStampProps} from 'src/components/modules/admin/messages/MessageCustomStampProvider'
import {SEARCH_RESULTS_LIMIT} from 'src/constants/search'
import {http} from 'src/services/http'
import {MessageProps} from 'src/types/message'

export async function sendMessageApi(
  message: FormData | string,
  bookingId: number,
  no_require_notification?: boolean,
) {
  let messageData = null
  if (typeof message === 'string') {
    messageData = {
      message,
      no_require_notification: no_require_notification ?? false,
    }
  } else {
    messageData = message
  }

  const {data} = await http.post(`/booking/${bookingId}/messages`, messageData)

  return data
}

export async function getInboxApi(
  isHost: number,
  color: string,
  lead: string,
  priority: string,
  page = 1,
  limit = SEARCH_RESULTS_LIMIT,
) {
  const {data} = await http.get('/message_masters', {
    params: {
      page,
      limit,
      is_host: isHost,
      color: color === 'all' ? '' : color,
      lead: lead === 'all' ? '' : lead,
      priority: priority === 'all' ? '' : priority,
    },
  })

  return data
}

export async function getMessagesApi(
  bookingId: number,
  lastMessageId = 0,
  limit = 5,
) {
  const {data} = await http.get(`/booking/${bookingId}/messages`, {
    params: {
      last_id: lastMessageId,
      limit,
    },
  })

  return data
}

export async function setMessageReadApi(messageId: number) {
  const {data} = await http.put(`/messages/${messageId}/set_read`)

  return data
}

export async function getMessageByIdApi(bookingId: number, messageId: number) {
  const {data} = await http.get(`/booking/${bookingId}/messages/${messageId}`)

  return data
}

export async function setMessagesReadApi(messageIds: Number[]) {
  const {data} = await http.put(`/messages/set_read`, {
    ids: messageIds,
  })

  return data
}

export async function getMessageBadgesApi() {
  const {data} = await http.get(`/messages/badges`)

  return data
}

export async function setMessagesReadByBookingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/set_read`)

  return data
}

export async function getMessageCustomStampsApi(messageMasterId: number) {
  const {data} = await http.get(
    `/message_master/${messageMasterId}/message_custom_stamps`,
  )

  return data
}

export async function createMessageCustomStampApi(
  messageMasterId: number,
  messageCustomStamp: MessageCustomStampProps,
) {
  const {data} = await http.post(
    `/message_master/${messageMasterId}/message_custom_stamp`,
    messageCustomStamp,
  )

  return data
}

export async function updateMessageCustomStampApi(
  messageCustomStampId: number,
  messageCustomStamp: MessageCustomStampProps,
) {
  const {data} = await http.put(
    `/message_custom_stamp/${messageCustomStampId}`,
    messageCustomStamp,
  )

  return data
}

export async function removeMessageCustomStampApi(
  messageCustomStampId: number,
) {
  const {data} = await http.delete(
    `/message_custom_stamp/${messageCustomStampId}`,
  )

  return data
}

export async function adminRemoveMessageApi(message: MessageProps) {
  const {data} = await http.delete(`/admin/messages/${message.id}`)

  return data
}

export async function adminEditMessageApi(message: MessageProps, text: string) {
  const {data} = await http.put(`/admin/messages/${message.id}`, {
    message: text,
  })

  return data
}
