import Cookies from 'js-cookie'
import {CookieConsentSettingsType} from 'src/types/cookies'

/*todo
export function extractLoginCookieServerSide(
  context: GetServerSidePropsContext,
) {
  const cookies = context.req.headers.cookie
  if (cookies) {
    let cookieData = cookie.parse(cookies)
    if (cookieData) {
      http.defaults.headers.common.Authorization = `Bearer ${cookieData.jwt}`
    }
  }
} */

export function getCookiesConsentSettingsClientSide(): CookieConsentSettingsType | null {
  let cookieConsentDataJson: string | undefined = Cookies.get(
    'cookiesConsentSettings',
  )

  if (!cookieConsentDataJson) {
    return null
  }

  let cookieConsentData: CookieConsentSettingsType | null = null
  try {
    cookieConsentData = JSON.parse(cookieConsentDataJson)
  } catch (e) {
    return null
  }

  if (!cookieConsentData) {
    return null
  }

  return cookieConsentData
}
